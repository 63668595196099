<template>
    <div class="product-swiper" v-if="newImages.length">
      <swiper
        class="product-swiper__top"
        :modules="modules"
        :speed="400"
        :thumbs="{ swiper: thumbsSwiper }"
      >
        <template v-if="newImages[0]">
          <swiper-slide v-for="(image) in newImages" :key="image">
            <img :src="cdn + image" alt="" />
          </swiper-slide>
        </template>
        <template v-else>
          <swiper-slide>
            <img :src="require('../assets/img/license/license1.png')" alt="" />
          </swiper-slide>
        </template>
      </swiper>
      <div class="product-swiper__container">
        <div class="product-swiper-left">
          <img src="../assets/img/icon/arr-category1.png" alt="" />
        </div>
        <swiper
          class="product-swiper__thumbs"
          :slides-per-view="4"
          :slides-per-group="1"
          :speed="550"
          :modules="modules"
          :navigation="categoryNavigation"
          :watchSlidesProgress="true"
          @swiper="setThumbsSwiper"
          @slideChange="setThumbsSwiper"
          :breakpoints="breakpoints" 
        >
          <template v-if="newImages[0]">
            <swiper-slide v-for="(image, i) in newImages" :key="i">
              <img :src="cdn + image" alt="" />
            </swiper-slide>
          </template>
          <template v-else>
            <swiper-slide>
              <img :src="require('../assets/img/license/license1.png')" alt="" />
            </swiper-slide>
          </template>
        </swiper>
        <div class="product-swiper-right">
          <img src="../assets/img/icon/arr-category1.png" alt="" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import { Thumbs, FreeMode, EffectFade } from "swiper";
  import { Swiper, SwiperSlide } from "swiper/vue";
import { mapState } from 'vuex';
  
  export default {
    name: "ThumbsSwiper",
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      productImages: {
        type: Array,
        required: true,
      },
      mainImage: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState(["cdn"])
    },
    // mounted() {
    //   this.newImages.push(this.mainImage);
    //   console.log(this.mainImage);
    //   this.productImages?.map((item) =>
    //     this.mainImage !== item ? this.newImages.push(item) : null
    //   );
    // },
    data() {
      return {
        newImages: [],
        categoryNavigation: {
          prevEl: ".product-swiper-left",
          nextEl: ".product-swiper-right",
        },
        breakpoints: {
          0: {
            spaceBetween: 10,
            slidesPerView: 4,
          },
          1366.98: {
            spaceBetween: 10,
          },
        },
      };
    },
    watch: {
      mainImage() {
        this.newImages = [];
        this.newImages.push(this.mainImage);
        this.productImages.map((item) =>
          this.mainImage !== item.image_url ? this.newImages.push(item.image_url) : null
        );
      },
    },
    setup() {
      let thumbsSwiper = ref(null);
      const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
      };
      return {
        modules: [Thumbs, FreeMode, EffectFade],
        thumbsSwiper,
        setThumbsSwiper,
      };
    },
  };
  </script>
  
  <Style lang="scss" scoped>
  $pc: 1466;
  $tablet: 992;
  $mobile: 667;
  $mobileSmall: 520;
  .swiper-slide {
    width: 100%;
  }
  .swiper-pagination-bullets {
    top: 90% !important;
  }
  .swiper-pagination-bullet-active {
    background: #282828 !important;
  }
  .mobile-swiper {
    margin-bottom: -20px;
    display: none;
    width: 100%;
    @media (max-width: $mobile + px) {
      display: block;
      height: 315px;
      &__top {
        height: 100%;
        img {
          width: 100%;
          height: 250px;
        }
      }
    }
  }
  .product-swiper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 0 15px;
    max-width: 100%;
    &-right,
    &-left {
      display: block !important;
      width: 8px;
      height: 12.33px;
      filter: contrast(10%);
      &:hover {
        cursor: pointer;
        filter: contrast(100%);
      }
    }
    .swiper-button-disabled {
      visibility: hidden;
    }
    @media (max-width: $pc + px) {
      max-width: 100%;
    }
    @media (max-width: 1450px) {
      height: 100%;
    }
    @media (max-width: $tablet + px) {
      width: 100%;
      margin: 0 auto 0 0;
    }
    @media (max-width: $mobile + px) {
      // display: none;
    }
    @media (max-width: $mobileSmall + px) {
      // height: 276px;
      gap: 0 21px;
    }
  
    &__container {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      padding: 24px 0;
      @media (max-width: $mobileSmall + px) {
        padding: 17px 0;
      }
    }
    &__top {
      height: 100%;
      .swiper-slide {
        /* border: 1px solid #ececec;
        border-radius: 10px; */
        overflow: hidden;
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 100%;
        height: 563px;
        img {
          object-fit: cover;
          height: 563px;
          width: 590px;
          max-width: 100%;
          max-height: 100%;
          border-radius: 10px;
          box-shadow: 0px 4px 24px rgba(19, 46, 89, 0.3), 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
      @media (max-width: $tablet + px) {
        width: 100% !important;
      }
    }
  
    &__thumbs {
      .swiper-slide {
        // width: 100px !important;
        // height: 100px !important;
        border: 1px solid white;
        padding: 0px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          object-fit: cover;
          height: 100px;
          width: 100px;
          border-radius: 10px;
          pointer-events: none;
        }
      }
  
      @media (max-width: $tablet + px) {
        width: 100% !important;
      }
    }
  
    &__thumbs {
      .swiper-slide {
        cursor: pointer;
        &-thumb-active {
          // border: 2px solid #5BC6F1;
          border-radius: 9px;
          // img {
          //   width: 100%;
          // }
          // width: 100px !important;
          // height: 100px;
        }
      }
      @media (max-width: $pc + px) {
        width: 80px;
      }
      @media (max-width: $mobile + px) {
        width: 63px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 46px;
      }
    }
  
    &__top,
    &__thumbs {
      width: 100%;
      &.swiper {
        margin: 0;
        width: 100%;
      }
      @media (max-width: $pc + px) {
        width: 100% !important;
      }
      @media (max-width: $mobile + px) {
        width: 454.5px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 276px;
      }
    }
  }
  @media (max-width: 920px) {
    .product-swiper {
      &__top {
        .swiper-slide {
          height: 100%;
          img {
            width: 100% !important;
            height: 360px !important;
          }
        }
      }
      &__thumbs {
        .swiper-slide {
          img {
            max-height: 60px;
          }
        }
      }
    }
  }
  @media (min-width: 1441px) {
    .product-swiper {
      &__top {
        .swiper-slide {
          img {
            width: 100% !important;
          }
        }
      }
    }
  }
  </Style>