<template>
    <div class="container">
        <div class="product">
            <h3 class="product-title title-blue">{{ $t("nav.product") }}</h3>
            <div class="product-cards m-none">
                <div class="product-cards__item" v-for="pr in product.homePageProductCategories" :key="pr.id">
                    <a class="link"><img class="product-img" :src="'https://admin.big-stroyservice.kz/' + pr.image" alt=""></a>
                    <div class="card">
                        <a :href="'/catalog/' + pr.id"><h4 v-if="pr.title" v-html="pr.title"></h4></a>
                    </div>
                </div>
            </div>
            <div class="product-slider d-none">
                <swiper :navigation="navigation" :modules="modules" :slidesPerGroup="1" :slidesPerView="1" :loop="true" class="mySwiper">
                    <swiper-slide v-for="pr in product.homePageProductCategories" :key="pr.id">
                        <div class="product-cards__item">
                            <img class="product-img" :src="'https://admin.big-stroyservice.kz/' + pr.image" alt="">
                            <div class="card">
                                <a :href="'/catalog/' + pr.id"><h4 v-if="pr.title" v-html="pr.title"></h4></a>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="nav-btn">
                    <div class="swiper-button-prev">
                        <img src="@/assets/img/icon/yellow-left.png" alt="">
                    </div>
                    <div class="swiper-button-next">
                        <img src="@/assets/img/icon/yellow-right.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="service">
            <div class="service-title title-blue">{{ $t("nav.service") }}</div>
            <div class="service-cards">
                <div class="service-cards__item" v-for="sr in product.homePageServiceLists" :key="sr.id">
                    <a class="link"><img class="service-img" :src="'https://admin.big-stroyservice.kz/' + sr.image" alt=""></a>
                    <div class="card">
                        <div class="box">
                        <a :href="'/catalog/' + sr.id">
                            <h4 v-if="sr" v-html="sr.title"></h4>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
        return {
            product: [],
            link: [],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        }
    },
    setup() {
      return {
        modules: [Navigation],
      };
    },
    created() {
        this.getHome();
        this.getProductLink()
    },
    methods: {
        routeProduct() {
            alert("asdasd")
        },
        getHome() {
            axios.get('page/home')
            .then(response => {
                this.product = response.data.data
            })
            .catch(e => {
                this.errors.push(e)
            })
        },
        getProductLink() {
            axios.get('products')
            .then(response => {
                this.link = response.data.data
            })
            .catch(e => {
                this.errors.push(e)
            })
        }
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
.nav-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 28%;
    left: 41%;
    height: 25px;
    .swiper {
        &-button {
            &-prev, &-next {
                &::after {
                    opacity: 0;
                }
            }
        }
    }
}
.product {
    padding: 78px 0 87px 0;
    &-title {
        padding-bottom: 18px;
    }
    &-img {
        width: 100%;
        min-height: 192px;
        transition: 0.4s;
    }
    &-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        &__item {
            width: 33%;
            margin-top: 17px;
            position: relative;
            cursor: pointer;
            transition: 0.4s;
            &:hover {
                .link {
                    transition: 0.4s;
                    &:after {
                    content: "";
                    display: block;    
                    width: 100%;
                    height: 100%;    
                    position: absolute;
                    top: 0;      
                    left: 0;   
                    background: transparent;
                    background-image: linear-gradient(0deg, rgba(19, 46, 89, 0.8), rgba(19, 46, 89, 0.8));
                    opacity: 0.8;
                    border-radius: 15px;
                    transition: 0.4s;
                }
                }
                .product-img {
                    transition: 0.4s;
                    filter: blur(4px);
                }
            }
        }
    }
}
.service {
    &-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__item {
            width: 49%;
            margin-top: 17px;
            position: relative;
            &:hover {
                .service-img {
                    transition: 0.4s;
                    filter: blur(4px);
                }
                .link {
                    transition: 0.4s;
                    &:after {
                        content: "";
                        display: block;    
                        width: 100%;
                        height: 100%;    
                        position: absolute;
                        top: 0;      
                        left: 0;   
                        background: transparent;
                        background-image: linear-gradient(0deg, rgba(19, 46, 89, 0.8), rgba(19, 46, 89, 0.8));
                        opacity: 0.8;
                        border-radius: 15px;
                        transition: 0.4s;
                    }
                }
            }
        }
    }
    &-img {
        width: 100%;
        // max-width: 612px;
        // background: linear-gradient(180deg, rgba(19, 46, 89, 0) 20.81%, rgba(19, 46, 89, 0.8) 74.28%), #D9D9D9;
        // box-shadow: 0px 4px 14px rgba(91, 198, 241, 0.2);
        border-radius: 10px;
        min-height: 192px;
        object-fit: cover;
        transition: 0.4s;
        &:hover {
            transition: 0.4s;
            filter: blur(4px);
        }
    }
}
.card {
    position: absolute;
    bottom: 22%;
    left: 10%;
    right: 5%;
    h4 {
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
    a {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        text-decoration: none;
    }
}
@media (max-width: 920px) {
    .product {
        padding: 60px 0;
        &-cards {
            &__item {
                width: 100%;
            }
        }
        &-title {
            padding-bottom: 0;
        }
    }
    .card {
        h4 {
            font-size: 20px;
            line-height: 25px;
        }
    }
    .service {
        &-cards {
            flex-direction: column;
            &__item {
                width: 100%;
            }
        }
        &-img {
            min-height: 192px;
        }
    }
}
@media (min-width: 921px) and (max-width: 1390px) {
    .card {
        bottom: 15%;
        h4 {
            font-size: 18px;
            line-height: 22px;
        }
    }
}
</style>