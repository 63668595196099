<template>
    <div class="dev">
        <div class="container">
            <div class="del-title title-blue" v-if="cnt1" v-html="cnt1"></div>
            <div class="dev-btm">
                <div class="dev-btm__first" v-if="del.homePageMethodsLeftImage">
                    <img :src="'https://admin.big-stroyservice.kz/' + img" alt="">
                    <p class="sm-title" v-if="cnt2" v-html="cnt2"></p>
                </div>
                <div class="dev-btm__sec" v-if="del.homePageMethodsLists">
                    <div class="card" v-for="dl in del.homePageMethodsLists" :key="dl.id">
                        <h5 class="md-title" v-html="dl.title"></h5>
                        <p class="sm-title" v-html="dl.text">
                        </p>
                    </div>
                </div>
                <div class="dev-btm__third">
                    <img :src="'https://admin.big-stroyservice.kz/' + img1" alt="">
                    <p v-if="cnt3" class="sm-title" v-html="cnt3"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            del: []
        }
    },
    created() {
        axios.get('page/home') 
        .then(response => {
            this.del = response.data.data;
            this.img = response.data.data.homePageMethodsLeftImage.link
            this.img1 = response.data.data.homePageMethodsRightImage.link
            this.cnt1 = response.data.data.homePageMethodsTitle
            this.cnt2 = response.data.data.homePageMethodsLeftImage.content
            this.cnt3 = response.data.data.homePageMethodsRightImage.content
        })
        .catch(e => {
            this.errors.push(e);
        })
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
.dev {
    background: #F0F4FD;
    padding: 68px 0 119px 0;
    &-btm {
        margin-top: 54px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        &__first {
            width: 17%; 
            margin-top: 30px;
            img {
                width: 211px;
                margin-bottom: 30px;
            }
        }
        &__sec {
            width: 33%;
            margin-top: 30px;
        }
        &__third {
            width: 28%;
            img {
                margin-bottom: 30px;
                width: 100%;
            }
        }
    }
}
.card {
    margin-bottom: 19px;
    h5 {
        margin-bottom: 10px;
    }
}
@media (max-width: 920px) {
    .dev {
        &-btm {
            margin-top: 20px;
            flex-direction: column;
            &__first, &__sec, &__third {
                width: 100%;
            }
            &__first {
                img {
                    width: 136px;
                }
            }
        }
    }
}

</style>