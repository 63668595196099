<template>
    <div class="container content">
        <h4 class="page-title" v-html="policy.policyPageTitle.content"></h4>
        <p class="privacy" v-html="policy.policyPageDescription.content">
        </p>
        <p></p>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            policy: [],
        }
    },
    created() {
        this.getPolicyPage()
    },
    methods: {
        getPolicyPage() {
            axios.get('page/policy')
            .then(response => {
                this.policy = response.data.data
            })
            .catch(e=> {
                this.errors.push(e);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
h4 {
    margin-bottom: 25px;
}
.privacy {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #132E59;
}
@media (max-width: 920px) {
    .privacy {
        line-height: 25px;
    }
}
</style>