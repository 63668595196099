<template>
    <div class="bread-crumbs">
        <div class="align-center">
            <router-link v-for="(link, index) in links" :key="index" :to="link.url">
                {{ link.title }}
            <span v-if="links.length - 1 != index" class="ml-1 mr-1"> <img src="@/assets/img/icon/bread-crumb.svg" alt=""> </span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.bread-crumbs {
    margin-bottom: 26px;
    a {
        text-decoration: none;
        color: #77757F;
        img {
            margin: 0 7px 0 2px;
        }
    }
}
</style>