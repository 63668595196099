<template>
    <div class="container">
        <div class="nav">
            <div class="nav-top">
                <h4 class="md-title">{{ $t("nav.product") }}</h4>
                <button @click="routeMain"><img src="@/assets/img/icon/close.png" alt=""></button>
            </div>
            <div class="nav-box" v-for="item in cat"
                v-show="item.type == 'products'"
                :key="item.id"
                @click="active_answer = item.id, $router.push(`/catalog/${item.id}`)">
                <div class="nav-box__text" @click="IsActiveButton == false" :class="{ active_title: active_answer === item.id }">
                    <p class="blue-md-text" style="color: #132e59;">
                        {{ item.title }}
                    </p>
                    <span v-show="active_answer != item.id">
                        <img src="@/assets/img/icon/arr-category1.png" alt="" />
                    </span>
                    <span v-show="active_answer == item.id">
                        <img src="@/assets/img/icon/arr-category2.png" alt="" />
                    </span>
                </div>
                <div class="nav-box__under" v-show="active_answer == item.id" v-for="(und, index) in item.subCategories" :key="index">
                    <p @click="IsActiveButton(index)" :class="{ activeButton: index === IsActive }" @click.stop="$router.push(`/catalog/${active_answer}/${und.id}`)" class="px-13">{{ und.title }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
data() {
    return {
        category: false,
        cat: [],
        active_answer: [],
        active_title: [],
        IsActive: false
    }
},
created() {
    this.getCategory();
    this.active_answer = this.$route.fullPath.split('/')[2]
},
methods: {
    activeCategory() {
        this.category = true;
    },
    getCategory() {
        axios.get('categories')
        .then(response => {
            this.cat = response.data.data;
        })
        .catch (e=> {
            this.errors.push(e)
        })
    },
    routeMain() {
        this.$router.push('/')
    },
    IsActiveButton(index) {
      this.IsActive = index;
    },
},
watch: {
    $route() {
    this.active_answer = this.$route.fullPath.split('/')[2]

    }
}
}
</script>

<style lang="scss" scoped>
.activeButton {
    color: #5BC6F1;
}
.active_title {
    margin-bottom: 10px;
    p {
        color: #5BC6F1 !important;
        font-weight: 500;
    }
}
.nav {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 50px;
    &-top {
        display: flex;
        justify-content: space-between;
        button {
            background: #fff;
            border: none;
        }
    }
    &-box {
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(19, 46, 89, 0.15);
        border-radius: 10px;
        padding: 10px;
        margin-top: 12px;
        &__text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            cursor: pointer;
            p {
                width: 85%;
                margin-right: 15px;
            }
        }
        &__under {
            transition: all 0.6s;
            p {
                padding: 11px 0;
                border-top: 1px solid #F2F2F2;
                cursor: pointer;
                transition: 0.4s;
                &:hover {
                    color: #5BC6F1;
                    transition: 0.4s;
                }
            }
        }
    }
}
</style>