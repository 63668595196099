<template>
  <div id="app">
    <HeaderV/>
    <transition name="fade" mode="in-out">
      <router-view/>
    </transition>
    <FooterV/>
  </div>
</template>

<script>
import FooterV from './components/ui/FooterV.vue';
import HeaderV from './components/ui/HeaderV.vue';


export default {
  name: 'App',
  components: {
    HeaderV,
    FooterV
}
}
</script>

<style>
</style>
