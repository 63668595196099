<template>
    <div class="container content">
        <bread-crumbs :links="links" />
        <div class="about">
            <div v-if="about.aboutPageTitle" class="about-title title-blue" v-html="about.aboutPageTitle"></div>
            <div class="about-card">
                <div class="about-card__left">
                    <h4 v-if="about.aboutPageSubTitle" class="md-title" v-html="about.aboutPageSubTitle"></h4>
                    <p v-if="about.aboutPageLongText" class="about-desc" v-html="about.aboutPageLongText">
                    </p>
                </div>
                <div v-if="about.aboutPageImage" class="about-card__right">
                    <img :src="'https://admin.big-stroyservice.kz/' + about.aboutPageImage.link" alt="">
                </div>
            </div>
            <div class="about-btm">
                <div class="about-btm__card" v-for="ab in about.aboutPageItems" :key="ab.id">
                    <h4 v-html="ab.title"></h4>
                    <p v-for="list in ab.aboutItemsLists" :key="list.id"><span v-html="list.name"></span><a href="#" v-html="list.value"></a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {mapState} from 'vuex';
import breadCrumbs from '@/components/ui/breadCrumbs.vue';
import {useHead} from "@vueuse/head"
import { computed, reactive } from "vue"
export default {
    components: {breadCrumbs},
    data() {
        return {
            about: [],
            abouts: [],
            links: [
                    {
                        title: this.$t("main"),
                        url: "/",
                    },
                    {
                        title: this.$t("nav.about"),
                        url: "/about-us",
                    },
            ],
        }
    },
    setup() {
        const siteData = reactive({
            title: localStorage.getItem('meta_title') || 'страница О нас',
            description: localStorage.getItem('meta_description') || 'страница О нас',
        })

        useHead({
            meta: [
                {
                    name:  computed(() => siteData.title),
                    content: computed(() => siteData.description),
                },
            ],
        })
    },
    async created() {
       await axios.get('page/about')
        .then(response => {
            this.about = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
        localStorage.setItem('meta_title',this.about.meta_title)
        localStorage.setItem('meta_description',this.about.meta_description)
    },
    methods: {
    },
    computed: {
        ...mapState(['cdn']),
  },
}
</script>

<style lang="scss" scoped>
.about {
    &-card {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        width: 100%;
        &__left {
            width: 45%;
            h4 {
                margin-bottom: 20px;
            }
        }
        &__right {
            width: 49%;
            img {
                width: 100%;
                // max-height: 586px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }
    &-desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: #132E59;
    }
    &-btm {
        padding: 17px 0 0 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__card {
            width: 49%;
            margin-top: 80px;
            h4 {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: #132E59;
                margin-bottom: 12px;
            }
            p {
                display: flex;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.02em;
                color: #828282;
                a {
                    color: #132E59;
                    text-decoration: none;
                    margin-left: 5px;
                }
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
@media (max-width: 920px) {
    .about {
        &-card {
            flex-direction: column;
            &__left {
                width: 100%;
            }
            &__right {
                width: 100%;
                margin-top: 30px;
                img {
                    max-width: 450px;

                }
            }
        }
        &-btm {
            flex-direction: column;
            &__card {
                width: 100%;
                margin-top: 50px;
            }
        }
    }
}
</style>