<template>
    <div class="body-wrap">
        <MainSlider/>
        <ProductsV/>
        <HitsMain/>
        <levelMain/>
        <deliveryMain/>
        <PartnersMain/>
        <div class="whatsapp">
            <a v-if="contact[2]" target="_blank" :href="contact[2].link"><img src="@/assets/img/icon/whatsapp-logo.png" alt=""></a>
        </div>
    </div>
</template>

<script>
import HitsMain from '@/components/main/hits-main.vue';
import MainSlider from '@/components/main/mainSlider.vue';
import ProductsV from '@/components/main/productsV.vue';
import levelMain from '@/components/main/level-main.vue';
import deliveryMain from '@/components/main/delivery-main.vue'
import PartnersMain from '@/components/main/partners-main.vue';
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    components: { MainSlider, ProductsV, HitsMain, levelMain, deliveryMain, PartnersMain },
    data() {
        return {
            contact: [],
        }
    },
    created() {
        this.getContact()
    },
    methods: {
        getContact() {
            axios.get('page/contacts')
            .then(response => {
                this.contact = response.data.data.socials;
            })
            .catch(e=> {
                this.errors.push(e)
            })
        }
    },
    computed: {
        ...mapState(["cdn"])
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
    position: relative;
}
.whatsapp {
    position: fixed;
    bottom: 3%;
    right: 5%;
    z-index: 333;
}
@media (max-width: 920px) {
    .whatsapp {
        bottom: 10%;
    }
}
</style>