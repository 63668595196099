<template>
    <div class="main contentSlider">
        <swiper :pagination="{
            clickable: true
        }" :modules="modules" class="mySwiperSlide">
            <swiper-slide v-for="hm in home.homePageSliders" :key="hm.id">
                <div class="main-slide first" :style="{backgroundImage:`url(${'https://admin.big-stroyservice.kz/' + hm.image})`}">
                    <div class="container">
                        <div class="title" v-html="hm.title"></div>
                        <button @click="isOpen = true" class="main-slider__btn buttonSlide">{{ $t("application") }}</button>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <modalVue :open="isOpen" @close="isOpen = !isOpen">
            <form @submit.prevent="sendApplication" class="modal-wrap">
                <h4 class="px-24-600">{{ $t("application") }}</h4>
                <h6 class="px14-400">{{ $t("min5") }}</h6>
                <div class="modal-inputs">
                    <input v-model="name" name="name" type="text" class="input" :placeholder="$t('name')" required :on-invalid="$t('name')" oninput="setCustomValidity('')">
                    <input v-model="phone" name="phone" v-mask="'+7(###)-###-##-##'" type="text" class="input" placeholder="+7" required>
                </div>
                <h3 class="px-13">{{ $t("pol1") }} <a v-for="ct in contact" :key="ct" style="color: #132E59;" :href="cdn + ct.link">{{ $t("pol2") }}</a> {{ $t("pol3") }} <a v-for="tr in term" :key="tr.id" style="color: #132E59;" :href="cdn + tr.link">{{ $t("pol4") }}</a>.</h3>
                <button @submit.prevent="sendApplication" class="button">{{ $t("send") }}</button>
            </form>
        </modalVue>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import modalVue from "../modal/modalVue.vue";
import { ref } from "vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import axios from "axios";
import {mapState} from 'vuex'
import {useHead} from "@vueuse/head"
import { computed, reactive } from "vue"
export default {
components: {
    Swiper,
    SwiperSlide,
    modalVue
},
data() {
    return {
        home: [],
        name: "",
        phone: "",
        contact: [],
        term: [],
    }
},
async created() {
    await axios.get('page/home')
    .then(response => {
        this.home = response.data.data
    })
    .catch(e => {
        this.errors.push(e)
    })
    this.getContact();
    localStorage.setItem('meta_title', this.home.meta_title)
    localStorage.setItem('meta_description',this.home.meta_description)
},
setup() {
    const siteData = reactive({
        title: localStorage.getItem('meta_title') || 'Главная страница',
        description: localStorage.getItem('meta_description') || 'Главная страница',
    })

    useHead({
        meta: [
            {
                name:  computed(() => siteData.title),
                content: computed(() => siteData.description),
            },
        ],
    })
    const isOpen = ref(false);
    return {
        modules: [Pagination],
        isOpen
    };
},
methods: {
    sendApplication() {
        this.form = {
            name: this.name,
            phone: this.phone
        }
        axios.post('applications', this.form)
        .then(response => {
            if(response.status == 200) {
                alert('Ваша заявка отправлена успешно!');
                this.isOpen = false
                this.name = "";
                this.phone = ""
            }
            else if(response.status == 422) {
                alert('error!')
            }
        })
        .catch(e => {
            this.errors.push(e)
        }) 
    },
    getContact() {
        axios.get('page/contacts')
        .then(response => {
            this.contact = response.data.data.policy;
            this.term = response.data.data.terms;
        })
        .catch(e=> {
            this.errors.push(e)
        })
    },
},
computed: {
    ...mapState(['cdn'])
},
watch: {
    isOpen: function () {
        if (this.isOpen) {
          document.documentElement.style.overflow = "hidden";
          return;
        }
        document.documentElement.style.overflow = "auto";
      },
}
}
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 100%;
}
.first {
    background-image: url('@/assets/img/3.png');
    padding-top: 150px;
    background-position: top;
    div {
        color: #132E59;
    }
}
.second {
    background-image: url('@/assets/img/slider2.png');
    padding-top: 90px;
    div {
        color: #132E59;
    }
}
.third {
    background-image: url('@/assets/img/1.png');
    padding-top: 130px;
    div {
        color: #132E59;
    }
}
.first, .second, .third {
    background-size: cover;
    background-repeat: no-repeat;
    height: 90vh;
}
@media (min-width: 920px) and (max-width: 1240px) {
    .first {
        padding-top: 90px;
    }
}
@media (max-width: 920px) {
    .first {
        padding-top: 52px;
        height: 56vh;
    }
}
</style>