<template>
    <div class="content ">
        <div class="container">
        <bread-crumbs :links="links" />
        <p style="display: none; height: 0;">{{ this.links[1].title = product_title }}</p>
        <div class="card">
            <div class="card-left">
                <ThumbsSlider
                    :mainImage="mainImg"
                    :productImages="imagesPP"
                ></ThumbsSlider>
            </div>
            <div class="card-right">
                <h3 class="page-title d-none" v-html="product.title"></h3>
                <div class="card-right__wrap">
                    <div class="card-right__first">
                        <div class="text">
                            <p class="page-title m-none" v-html="product.title"></p>
                            <div class="text-price text-md">
                                <h5 class="px-24-600">{{ formatPrice(product.price) }} </h5>
                                <p v-if="product.status_code = 1" class="light-blue-14">{{ $t("stock") }}</p>
                                <p v-else class="light-blue-14">{{ $t("none") }}</p>
                            </div>
                            <p style="color: #132E59;" class="blue-md-text">{{ $t("minPrice") }} — {{ formatPrice(180000) }}. </p>
                        </div>
                    </div>
                    <div class="card-right__sec">
                        <div class="star">
                            <star-rating v-model:rating="rating" :increment="0.5"></star-rating>
                            <p>{{ rating = product.rate }}</p>
                        </div>
                        <!-- <img src="../../assets/img/icon/star.png" alt=""> -->
                        <p class="sm-desc">{{ $t("code") }}: <span style="color: #555555; font-weight: 600;">{{ product.vendor_code }}</span></p>
                    </div>
                </div>
                <div class="card-right__btn">
                    <router-link to="/order" style="text-decoration: none;"><button @click="toLocal" class="button">{{ $t("orderBtn") }}</button></router-link>
                    <button class="button" @click="isOpen1 = true">
                        <svg class="button-svg" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 0.5C1.11929 0.5 0 1.61929 0 3V5C0 10.799 4.70101 15.5 10.5 15.5H12.5C13.8807 15.5 15 14.3807 15 13V11.618C15 11.0499 14.679 10.5305 14.1708 10.2764L11.7549 9.06843C10.9384 8.6602 9.94971 9.06975 9.66105 9.93573L9.36328 10.829C9.25014 11.1684 8.90197 11.3705 8.55114 11.3003C6.35528 10.8612 4.63885 9.14472 4.19967 6.94886C4.12951 6.59803 4.33156 6.24986 4.67097 6.13672L5.7796 5.76718C6.52319 5.51932 6.95058 4.74075 6.76048 3.98035L6.17444 1.6362C6.0075 0.968446 5.40752 0.5 4.71922 0.5H2.5Z" fill="#F9AC3A"/>
                        </svg>
                        {{ $t("call") }}
                    </button>
                </div>
                <div class="card-modal">
                    <modalVue :open="isOpen1" @close="isOpen1 = !isOpen1">
                        <form @submit.prevent="sendApplication" class="modal-wrap">
                            <h4 class="px-24-600">{{ $t("call") }}</h4>
                            <h6 class="px14-400">{{ $t("min5") }}</h6>
                            <div class="modal-inputs">
                                <input v-model="name" autocomplete="on" type="text" name="name" class="input" :placeholder="$t('name')" required>
                                <input v-model="phone" v-mask="'+7(###)-###-##-##'" type="text" name="phone" class="input" placeholder="+7" required>
                            </div>
                            <h3 class="px-13">{{ $t("pol1") }}<a v-for="ct in contact" :key="ct" style="color: #132E59;" :href="cdn + ct.link">{{ $t("pol2") }}</a> {{ $t("pol3") }} <a v-for="tr in term" :key="tr.id" style="color: #132E59;" :href="cdn + tr.link">{{ $t("pol4") }}</a>.</h3>
                            <button @submit.prevent="sendApplication" class="button">{{ $t("send") }}</button>
                        </form>
                    </modalVue>
                </div>
                <div class="card-question">
                    <div class="faq__items">
                        <div
                            class="faq__item"
                            @click="active_answer = answer[0].id"
                            v-if="product.description"
                            >
                            <!-- <div class="faq__devider">{{ active_answer }}</div> -->
                            <div
                                class="faq__item-title px-18"
                                :class="{ active_title: active_answer === answer[0].id }"
                                @click="visibleFaq = true, hideFaq = false"
                            >
                                {{ answer[0].title }}
                                <span :class="{ active_answer: active_answer === answer[0].id }">
                                    <img src="@/assets/img/icon/arrow-blue.png" alt="" />
                                </span>
                            </div>
                            <div class="faq__visible" @click="hideFaq = true, visibleFaq = false" :class="{'hideFaq': hideFaq}" v-if="active_answer === answer[0].id"></div>
                            <div :class="{'showFaq': visibleFaq, 'hideFaq': hideFaq}" class="faq__item-text" v-show="active_answer === answer[0].id" v-html="product.description">
                            </div>
                        </div>
                        <div
                            class="faq__item"
                            @click="active_answer = answer[1].id"
                            v-if="product.specification_items"
                            >
                            <div
                                class="faq__item-title px-18"
                                :class="{ active_title: active_answer === answer[1].id }"
                                @click="visibleFaq = true, hideFaq = false"
                            >
                                {{ answer[1].title }}
                                <span :class="{ active_answer: active_answer === answer[1].id }">
                                    <img src="@/assets/img/icon/arrow-blue.png" alt="" />
                                </span>
                            </div>
                            <div class="faq__visible" @click="hideFaq = true, visibleFaq = false" :class="{'hideFaq': hideFaq}" v-if="active_answer === answer[1].id"></div>
                            <div :class="{'showFaq': visibleFaq, 'hideFaq': hideFaq}" class="faq__item-text px-18-400" v-show="active_answer === answer[1].id">
                                <p class="gray-14">{{ $t("country") }}: <span style="color: #132E59;">{{ product.country_name }}</span> </p>
                                <p v-for="pr in product.specification_items" :key="pr.id" class="gray-14">{{ pr.title }}<span style="color: #132E59;">{{ pr.value }}</span> </p>
                            </div>
                        </div>
                        <div
                            class="faq__item"
                            @click="active_answer = answer[2].id"
                            v-if="product.payment_text"
                            >
                            <div class="faq__devider"></div>
                            <div
                                class="faq__item-title px-18"
                                :class="{ active_title: active_answer === answer[2].id }"
                                @click="visibleFaq = true, hideFaq = false"
                            >
                                {{ answer[2].title }}
                                <span :class="{ active_answer: active_answer === answer[2].id }">
                                    <img src="@/assets/img/icon/arrow-blue.png" alt="" />
                                </span>
                            </div>
                            <div class="faq__visible" @click="hideFaq = true, visibleFaq = false" :class="{'hideFaq': hideFaq}" v-if="active_answer === answer[2].id"></div>
                            <div :class="{'showFaq': visibleFaq, 'hideFaq': hideFaq}" class="faq__item-text" v-show="active_answer === answer[2].id" v-html="product.payment_text">
                            </div>
                        </div>
                        <div
                            class="faq__item"
                            @click="active_answer = answer[3].id"
                            v-if="product.reviews"
                            >
                            <div class="faq__devider"></div>
                            <div
                                class="faq__item-title px-18"
                                :class="{ active_title: active_answer === answer[3].id }"
                                @click="visibleFaq = true, hideFaq = false"
                            >
                                {{ answer[3].title }}
                                <span :class="{ active_answer: active_answer === answer[3].id }">
                                    <img src="@/assets/img/icon/arrow-blue.png" alt="" />
                                </span>
                            </div>
                            <div class="faq__visible" @click="hideFaq = true, visibleFaq = false" :class="{'hideFaq': hideFaq}" v-if="active_answer === answer[3].id"></div>
                            <div :class="{'showFaq': visibleFaq, 'hideFaq': hideFaq}" class="faq__item-text px-18-400" v-show="active_answer === answer[3].id">
                                <div class="review">
                                    <div class="review-card" v-for="rw in product.reviews" :key="rw">
                                        <p class="review-card__name sm-title-500" v-html="rw.name"></p>
                                        <div class="review-card__rate">
                                            <star-rating v-model:rating="rw.rate" :increment="0.5"></star-rating>
                                            <p class="date px15-gray" v-if="rw.created_at">{{ rw.created_at }}</p>
                                        </div>
                                        <p class="review-card__text px14-400" style="line-height: 23px;" v-html="rw.review_text">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-review">
                    <button @click="isOpen = true" class="button">{{ $t("sendRev") }}</button>
                </div>
                <modalVue :open="isOpen" @close="isOpen = !isOpen">
                    <form @submit.prevent="sendReview" class="modal-wrap">
                        <h4 class="modal-wrap__title px-24-600">{{ $t("writeRev") }}</h4>
                        <div class="modal-rate">
                            <p class="px-14-600">{{ $t("starProd") }}</p>
                            <star-rating v-model:rating="rating" :increment="0.5"></star-rating>
                        </div>
                        <input v-model="name" type="text" name="name" :placeholder="$t('name')" class="input gray-12" :class="{
                            invalid: $v.name.$dirty && !$v.name.required,
                        }" required>
                        <div class="err" v-if="$v.name.$error">
                            required field
                        </div>
                        <p style="display: none;">{{ product_id == product.id }}</p>
                        <div class="modal-card">
                            <img :src="cdn + product.image" alt="">
                            <div class="modal-card__text">
                                <h5 class="blue-md-text" style="color: #132E59;">{{ product.title }}</h5>
                                <p class="sm-text">{{ $t("code") }}: {{ product.vendor_code }}</p>
                                <p class="sm-text" v-if="product.status_code == 1">{{ $t("stock") }}</p>
                                <p class="sm-text" v-else>{{ $t("none") }}</p>
                                <p class="sm-text">{{ $t("price") }}: {{ formatPrice(product.price) }}</p>
                            </div>
                            <div class="modal-card__price m-none">
                                <p class="sm-text">{{ $t("piece") }}</p>
                                <h5 class="px-18">{{ formatPrice(product.price) }}</h5>
                            </div>
                        </div>
                        <div class="modal-review">
                            <div class="textarea-wrap">
                                <textarea class="gray-16" v-model="review_text" :placeholder="$t('sendRev')" name="" maxlength="1000" cols="30" rows="10" required> </textarea><span class="message-counter gray-16">{{ review_text.length }}/1000</span>
                            </div>
                        </div>
                        <div class="modal-btn">
                            <button @submit.prevent="sendReview" class="button">{{ $t("send") }}</button>
                        </div>
                    </form>
                </modalVue>
            </div>
        </div>
        <div class="similar m-none">
            <h5 class="title-blue">{{ $t("similar") }}</h5>
            <div class="similar-items">
                <CardVue v-for="item in product.similarProducts" :key="item.id"
                    :products="item" class="similar-items__item"/>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex';
import CardVue from './CardVue.vue';
import { Thumbs } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// import SimilarProduct from '../product/SimailarProduct.vue'
import { ref } from "vue";
import modalVue from '../modal/modalVue.vue'
import breadCrumbs from "../ui/breadCrumbs.vue";
// import hitsMain from "../main/hits-main.vue";
import StarRating from 'vue-star-rating'
import ThumbsSlider from '../ThumbsSlider.vue';
SwiperCore.use([Navigation]);
export default {
  components: {
    // SimilarProduct,
    modalVue,
    breadCrumbs,
    StarRating,
    CardVue,
    ThumbsSlider
  },
  props: [ "products" ],
  data() {
    return {
        faqs: [],
        visibleFaq: "true",
        hideFaq: "false",
        question: [],
        contact: [],
        term: [],
        image: "",
        open: false,
        review_text: '',
        name: null,
        product: [],
        main_imageP: null,
        imagesP: null,
        product_title: "",
        phone: "",
        surname: 'text',
        product_id: null,
        active_answer: [],
        active_title: [],
        rating: null,
        resetableRating: 2,
        currentRating: "No Rating",
        mouseOverRating: null,
        navigation1: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        links: [
            {
                title: this.$t("main"),
                url: "/",
            },
            {
                title: "",
                url: "",
            },
        ],
        v$: useVuelidate(),
        answer: [
            {
                id: 1,
                title: this.$t("description"),
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.'
            },
            {
                id: 2,
                title: this.$t("char"),
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.'
            },
            {
                id: 3,
                title: this.$t("delivery"),
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.'
            },
            {
                id: 4,
                title: this.$t("rev"),
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta, maxime expedita aut earum numquam iste tempore molestiae fugit natus ab officia maiores architecto eius id voluptatem accusamus! Totam, deleniti laboriosam.'
            },
        ]
    }
  },
  setup() {
    const isOpen = ref(false);
    const isOpen1 = ref(false);
    const name = ref('')
    const rules = {
        name: {required, name}
    }
    const $v = useVuelidate(rules, {name})
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return { isOpen, isOpen1, $v, Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
      modules: [Navigation], };
  },
    validations () {
        return {
            name: { required }, // Matches this.firstName
            surname: { required }, // Matches this.lastName
            review_text: {
            required,
            },
        }
    },
    async created() {
        await this.getProduct();
        this.getContact()
    },
    mounted() {
        // this.getTranslate()
    },
  methods: {
    ...mapActions(["LOCALSTORAGE_PRODUCT"]),
    formatPrice: price => `${(+price).toLocaleString()} ₸`,
    sendReview() {
        this.form = {
            name: this.name,
            rating: this.rating,
            surname: this.surname,
            product_id: this.product.id,
            review_text: this.review_text,
        };
        axios.post('reviews', this.form)
        .then(response => {
            if(response.status == 200) {
                alert('Ваш отзыв успешно отправлен!');
                this.isOpen = false;
                this.name = "",
                this.rating = null,
                this.surname = "text",
                this.review_text = ""
                this.product_id = null
            }
            else {
                // console.log(response.status);
                alert('error!')
            }
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    sendApplication() {
        this.form = {
            name: this.name,
            phone: this.phone
        }
        axios.post('applications', this.form)
        .then(response => {
            if(response.status == 200) {
                alert('Ваша заявка отправлена успешно!');
                this.isOpen1 = false
                this.name = "";
                this.phone = ""
            }
            else {
                alert('error!')
            }
        })
        .catch(e => {
            this.errors.push(e)
        }) 
    },
    async getProduct() {
        await axios.get(`products/product?lang=ru&slug=${this.$route.params.slug}`)
        .then(response => {
            this.product = response.data.data
            this.product_title = response.data.data.title
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    showCurrentRating(rating) {
      this.currentSelectedRating =
        rating === 0
          ? this.currentSelectedRating
          : "Click to select " + rating + " stars";
    },
    setCurrentSelectedRating(rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },
    async toLocal() {
        console.log('a', this.product);
        await this.LOCALSTORAGE_PRODUCT(this.product)
    },
    getContact() {
        axios.get('page/contacts')
        .then(response => {
            this.contact = response.data.data.policy;
            this.term = response.data.data.terms;
        })
        .catch(e=> {
            this.errors.push(e)
        })
    },
  },
  computed: {
    currentRatingText() {
      return this.rating
        ? "You have selected " + this.rating + " stars"
        : "No rating selected";
    },
    mouseOverRatingText() {
      return this.mouseOverRating
        ? "Click to select " + this.mouseOverRating + " stars"
        : "No Rating";
    },
      mainImg() {
        return this.main_imageP;
      },
      imagesPP() {
        return this.imagesP;
      },
    ...mapState(['cdn'])
  },
  watch: {
    isOpen: function () {
      if (this.isOpen) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    isOpen1: function () {
      if (this.isOpen1) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    $route() {
        this.getProduct()
    },
    product() {
        (this.main_imageP = this.product.image),
        (this.imagesP = this.product.images);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/product.scss';
</style>