<template>
    <div class="">
        <div class="hits">
            <div class="container">
                <h3 class="title-blue">{{ $t("hits") }}</h3>
            <div class="hits-cards m-none">
                <CardVue v-for="item in hit" :key="item.id"
                :products="item"/>
            </div>
            </div>
            <div class="hits-slider d-none" >
                <swiper
                :slidesPerView="1.5"
                :loop="true"
                :slidesPerGroup="1"
                :spaceBetween="30"
                :modules="modules"
                :navigation="navigation"
                    class="mySwiper"
                >
                    <swiper-slide v-for="(h,i) in hit" :key="i">
                        <div class="hits-cards__item">
                            <router-link :to="{
                                path: `/card/${h.slug}`
                                }">
                                <img :src="'https://admin.big-stroyservice.kz/' + h.image" alt="">
                            </router-link>
                            <div class="box">
                                <div class="box-card">
                                    <h4 class="name blue-md-text">{{ h.title }}</h4>
                                    <p class="price blue-md-text">{{ formatPrice(h.price) }}</p>
                                    <p class="status blue-md-text" v-if="h.status_code == 1">{{ $t("stock") }}</p>
                                    <p class="status blue-md-text" v-else>{{ $t("none") }}</p>
                                </div>
                                <router-link to="/order"><button class="button">{{ $t("orderBtn") }} </button></router-link>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="nav">
                    <div class="swiper-button-prev">
                        <img src="@/assets/img/icon/yellow-left.png" alt="">
                    </div>
                    <div class="swiper-button-next">
                        <img src="@/assets/img/icon/yellow-right.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions} from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/css/navigation";
import 'swiper/css';
import { Navigation } from "swiper";
import CardVue from '../product/CardVue.vue';
export default {
    components: {
      Swiper,
      SwiperSlide,
      CardVue
    },
    props: [ "products" ],
    data() {
        return {
            hit: [],
            hit_title: [],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        }
    },
    created() {
        axios.get('best/products')
        .then(response => {
            this.hit = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    setup() {
        return {
            modules: [ Navigation],
        };
    },
    mounted() {
        this.getTitle();
    },
    methods: {
        ...mapActions(["LOCALSTORAGE_PRODUCT"]),
        getTitle() {
            axios.get('page/home')
            .then(response => {
                this.hit_title = response.data.data
            })
            .catch(e => {
                this.errors.push(e)
            })
        },
        async toLocal() {
            console.log('a', this.products);
            await this.LOCALSTORAGE_PRODUCT(this.products)
        },
        formatPrice: price => `${(+price).toLocaleString()} ₸`,
    },
    computed: {
        ...mapState(['cdn']),
    }
}
</script>

<style lang="scss" scoped>
.hits {
    padding: 74px 0;
    &-cards {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 35px;
        &__item {
            width: 18%;
            background: #FFFFFF;
            box-shadow: 0px 4px 24px rgba(19, 46, 89, 0.3);
            border-radius: 10px;
            margin-right: 2%;
            margin-bottom: 1%;
            button {
                a {
                    color: #F9AC3A;
                }
                &:hover {
                    a {
                        color: #132E59;
                    }
                }
            }
            img {
                border-radius: 10px 10px 0px 0px;
                height: 150px;
                width: 100%;
                object-fit: cover;
            }
            .box {
                padding: 10px 10px 25px 10px;
                height: 180px;
                h4, .price {
                    color: #132E59;
                }
                .price {
                    padding: 10px 0;
                }
                h4 {
                    height: 30px;
                    -ms-text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -ms-line-clamp: 2;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    display: -webkit-box;
                    display: box;
                    word-wrap: break-word;
                    -webkit-box-orient: vertical;
                    box-orient: vertical;
                }
                .status {
                    color: #5BC6F1;
                    padding-bottom: 10px;
                }
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }
    &-slider {
        position: relative;
    }
}
.swiper {
    padding: 19px 2px 25px 21px;
}
.nav {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 28%;
    left: 41%;
    height: 25px;
    margin-top: 25px;
    .swiper-button {
        &-prev, &-next {
            &::after {
                opacity: 0;
            }
        }
    }
}
@media (max-width: 920px) {
    .hits {
        padding: 60px 0;
        margin-bottom: 60px;
        &-slider {
            margin-top: 15px;
            .swiper-slide {
                width: 75%;
            }
        }
        &-cards {
            &__item {
                width: 100%;
                height: 320px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .box {
                    &-card {
                        height: 100px;
                    }
                }
            }
        }
    }
}

</style>