import { createStore } from 'vuex'
import axios from 'axios'

export default  createStore({
    state: {
        cdn: 'https://admin.big-stroyservice.kz/',
        about: {
            info: []
        },
        order_product: localStorage.getItem('product') || {},
        product: [],
        searchData: null,
    },
    mutations: {
        SET_SEARCH(state, result) {
            state.searchData = result
        },
        SET_ABOUT_INFO(state, data) {
            state.about.info = data;
        },
        SET_PRODUCT_CARD(state, data) {
            state.product.card = data
        },
        SET_LOCALSTORAGE_PRODUCT(state, data) {
            state.order_product = data
        }
    },
    actions: {
        async GET_SEARCH_RESULT({commit}, {search }) {
            let result = await axios
            .get('https://admin.big-stroyservice.kz/api/products' + '/search', {
                params: {
                  lang: localStorage.getItem('lang'),
                  search: search
                }
            })
            // console.log("searchData",result.data.data)
            commit('SET_SEARCH', result.data)
        },
        LOCALSTORAGE_PRODUCT({ commit }, product) {
            console.log('asdasd');
            localStorage.setItem("product", JSON.stringify(product.slug))
            commit('SET_LOCALSTORAGE_PRODUCT', product)
        },
        // страница О нас
        async GET_ABOUT_INFO(context) {
            await axios
                .get('https://admin.big-stroyservice.kz/api/page/about', {
                    params: {
                        lang: localStorage.getItem('lang')
                    }
                })
            .then(response => (context.commit('SET_ABOUT_INFO', response.data)));
        },
        async GET_PRODUCT_CARD(context) {
            await axios
                .get('https://admin.big-stroyservice.kz/api/products', {
                    params: {
                        lang: localStorage.getItem('lang')
                    }
                })
            .then(response => (context.commit('SET_PRODUCT_CARD', response.data.data)));
        },
    },
    getters: {
        STATE(state) {
            return state;
        },
        GET_CDN:(state) => state.cdn,
        GET_ORDER_PRODUCT:(state) => state.order_product,
        GET_PRODUCT_CARD: (state) => state.product,
        SEARCH_RESULT(state) {
            return state.searchData
        },
    }
})