<template>
    <div class="container content">
        <bread-crumbs :links="links" />
        <div class="contacts">
            <div class="contacts-left">
                <h4 class="page-title">{{ $t("nav.contact") }}</h4>
                <div class="contacts-left__nums">
                    <p class="blue-md-text" v-for="ct in contact.contacts" :key="ct.id">
                        <img :src="'https://admin.big-stroyservice.kz/' + ct.image_2" alt="">
                        <a v-html="ct.title"></a>
                    </p>
                </div>
                <div class="contacts-left__time">
                    <p class="blue-md-desc">{{ $t("graph") }}</p>
                    <div class="days" v-for="day in contact.workingHours" :key="day.id">
                        <p v-html="day.title"></p>
                    </div>
                </div>
            </div>
            <div class="contacts-right">
                <div class="contacts-right__map">
                    <iframe :src="contact.mapLinks[0].title" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import breadCrumbs from '@/components/ui/breadCrumbs.vue';
import axios from 'axios';
import {mapState} from 'vuex';
import {useHead} from "@vueuse/head"
import { computed, reactive } from "vue"
export default {
    components: {breadCrumbs},
    data() {
        return {
            contact: [],
            links: [
                {
                    title: this.$t("main"),
                    url: "/",
                },
                {
                    title: this.$t("nav.contact"),
                    url: "/Contacts",
                },
            ],
        }
    },
    setup() {
        const siteData = reactive({
            title: localStorage.getItem('meta_title') || 'страница Контакты',
            description: localStorage.getItem('meta_description') || 'страница Контакты',
        })

        useHead({
            meta: [
                {
                    name:  computed(() => siteData.title),
                    content: computed(() => siteData.description),
                },
            ],
        })
    },
    async created() {
        await axios.get('page/contacts')
        .then(response => {
            this.contact = response.data.data;
        })
        .catch(e=> {
            this.errors.push(e)
        })
        localStorage.setItem('meta_title',this.contact.meta_title)
        localStorage.setItem('meta_description',this.contact.meta_description)
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
.contacts {
    display: flex;
    width: 100%;
    &-left {
        width: 40%;
        &__nums {
            img {
                margin-right: 18px;
            }
            p {
                margin-top: 15px;
                display: flex;
            }
            a,p {
                text-decoration: none;
                color: #132E59;
            }
        }
        &__time {
            margin-top: 45px;
            .days {
                display: flex;
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 27px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.02em;
                    color: #132E59;
                    margin-right: 5px;
                }
            }
        }
    }
    &-right {
        width: 60%;
        &__social {
            display: flex;
            justify-content: space-between;
            img {
                margin-left: 20px;
            }
        }
        &__map {
            iframe {
                width: 100%;
                border-radius: 20px;
                border: none;
            }
        }
    }
}
@media (max-width: 920px) {
    .contacts {
        flex-direction: column;
        &-left, &-right {
            width: 100%;
        }
        &-right {
            &__social {
                flex-direction: column;
                .social {
                    img {
                        margin-left: 0;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
</style>