import { createWebHistory, createRouter } from 'vue-router';
import mainPage from '../pages/main-page.vue';
import aboutUs from '../pages/about-us.vue'
import licensePage from '../pages/license-page.vue'
import contactPage from '../pages/contact-page.vue'
import userAgree from '../components/user-agree.vue'
import privacyPolicy from '../components/privacy-policy.vue'
import orderPage from '../pages/order-page.vue'
import catalogVue from '../pages/catalogVue.vue'
import ProductCard from '../components/product/ProductCard.vue'
import photoGallery from '../pages/photo-gallery.vue'
import categoryPanel from '../components/product/categoryPage.vue'
import servicePanel from '../components/product/service-panel.vue'

const routes = [
    {
        path: '/',
        name: 'main-page',
        component: mainPage
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: aboutUs
    },
    {
        path: '/certificates',
        name: 'license-page',
        component: licensePage
    },
    {
        path: '/contacts',
        name: 'contact-page',
        component: contactPage
    },
    {
        path: '/user-agree',
        name: 'user-agree',
        component: userAgree
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: privacyPolicy
    },
    {
        path: '/order',
        name: 'order-page',
        component: orderPage
    },
    {
        // path: '/card',
        path: '/card/:slug',
        name: 'product-card',
        component: ProductCard
    },
    {
        path: '/catalog',
        name: 'catalogMain',
        component: catalogVue
    },
    {
        path: '/catalog/:id',
        name: 'catalog',
        component: catalogVue
    },
    {
        path: '/catalog/:id/:id',
        name: 'catalogId',
        component: catalogVue
    },
    {
        path: '/photo-gallery',
        name: 'photo-gallery',
        component: photoGallery
    },
    {
        path: '/category',
        name: 'category',
        component: categoryPanel
    },
    {
        path: '/service',
        name: 'service',
        component: servicePanel
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

export default router