<template>
    <div class="container content">
        <BreadCrumbs :links="links" />
        <div class="gallery">
            <h2 class="page-title">{{ $t("nav.gallery") }}</h2>
        </div>
      <span>
        <lightgallery 
        :settings="{ speed: 500, plugins: plugins }"
        :onInit="onInit" 
        :onBeforeSlide="onBeforeSlide" 
        class="gallery-images">
            <a href="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-1.png">
                <img src="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-1.png" />
            </a>
            <a href="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-2.png">
                <img src="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-2.png" />
            </a>
            <a href="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-3.png">
                <img src="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-3.png" />
            </a>
            <a href="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-2.png">
                <img src="https://admin.big-stroyservice.kz/uploads/images/galleries/gallery-2.png" />
            </a>
        </lightgallery>
        <!-- <lightgallery
        :settings="{ speed: 500, plugins: plugins }"
        :onInit="onInit" 
        :onBeforeSlide="onBeforeSlide" 
        class="gallery-images"
        >
           <a v-for="img in photo" :key="img.id" :href="'https://admin.big-stroyservice.kz' + img.image_url">
                <img alt="" :src="'https://admin.big-stroyservice.kz' + img.image_url" />
            </a>
        </lightgallery> -->
      </span>
    </div>
</template>

<script>
import axios from 'axios';
import BreadCrumbs from '@/components/ui/breadCrumbs.vue';
import 'swiper/css';
import 'swiper/css/pagination';
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import {useHead} from "@vueuse/head"
import { computed, reactive } from "vue"

export default {
    components: { BreadCrumbs, Lightgallery },
    data() {
        return {
            photo: [],
            meta: "",
            links: [
                {
                    title: this.$t("main"),
                    url: '/'
                },
                {
                    title: 'Фотогалерея',
                    url: '/photo-gallery'
                }
            ],
            plugins: [lgThumbnail, lgZoom],
        }
    },
    setup() {
        const siteData = reactive({
            title: localStorage.getItem('meta_title') || 'страница Фотогалерея',
            description: localStorage.getItem('meta_description') || 'страница Фотогалерея',
        })

        useHead({
            meta: [
                {
                    name:  computed(() => siteData.title),
                    content: computed(() => siteData.description),
                },
            ],
        })
    },
    async created() {
        await axios.get('page/gallery')
            .then(response => {
                this.photo = response.data.data
                this.meta = response.data
            })
            .catch(e => {
                this.errors.push(e)
            })
            localStorage.setItem('meta_title',this.meta.meta_title)
            localStorage.setItem('meta_description',this.meta.meta_description)
    },
    methods: {
        onInit: () => {
            console.log('lightGallery has been initialized');
        },
        onBeforeSlide: () => {
            console.log('calling before slide');
        },
    },
    computed: {
    },
    watch: {
        items() {
            this.$nextTick(() => {
                this.lightGallery.refresh();
            });
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-zoom.css';

.gallery {
    &-images {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;

        a {
            width: 19%;
            margin-right: 1%;
        }

        img {
            width: 100%;
            max-width: 237px;
            height: 253px;
            border-radius: 10px;
            object-fit: cover;
            margin-bottom: 20px;
            transition: 0.4s;

            &:hover {
                transform: scale(1.1);
                transition: 0.4s;
                box-shadow: 0px 4px 24px rgba(19, 46, 89, 0.3), 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }
    }

    &-mobile {
        margin-top: 20px;
    }
}

@media (max-width: 920px) {
    .gallery {
        &-images {
            a {
                width: 49%;

                img {
                    height: 150px;
                    max-width: none;
                }
            }
        }
    }
}
</style>