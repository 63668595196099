<template>
    <div class="container">
        <div class="partner">
            <h4 class="title-blue">{{ $t("partner") }}</h4>
            <div class="partner-slider">
                <swiper
            :slidesPerView="5"
            :spaceBetween="0"
            :breakpoints="swiperOptions2.breakpoints"
            :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            }"
            :loop="true"
            :modules="modules"
            class="mySwiper"
            >
                <swiper-slide v-for="pt in part.homePagePartnersLists" :key="pt.id">
                    <div class="partner-slider__img">
                        <img :src="'https://admin.big-stroyservice.kz/' + pt.image" alt="">
                    </div>
                </swiper-slide>
            </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {mapState} from 'vuex'
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            part:[],
            swiperOptions2: {
                breakpoints: {
                // when window width is >= 320px
                300: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                // when window width is >= 480px
                768: {
                    slidesPerView: 6,
                    spaceBetween: 25,
                },
                // when window width is >= 640px
                1024: {
                    slidesPerView: 7,
                    spaceBetween: 10,
                },
                },
            }
        }
    },
    created() {
        axios.get('page/home')
        .then(response => {
            this.part = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    setup() {
        return {
        modules: [Autoplay ],
        };
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
.partner {
    padding: 60px 0 100px 0;
    &-slider {
        margin-top: 25px;
        &__img {
            background: #FFFFFF;
            box-shadow: 0px 4px 14px rgba(91, 198, 241, 0.2);
            border-radius: 10px;
            width: 160px;
            height: 74px;
            margin: 4px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                object-fit: contain;
            }
        }
    }
}
@media (max-width: 920px) {
    .partner {
        padding: 72px 0;
        &-slider {
            &__img {
                width: 135px;
                height: 60px;
                img {
                    max-height: 60px;
                    max-width: 130px;
                }
            }
        }
    }
    // .swiper {
    //     &-slide {
    //         margin-right: 30px;
    //     }
    // }
}
</style>