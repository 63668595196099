<template>
    <div class="head-fix">
        <div class="head-wrap">
            <div class="container">
                <div class="head" :class="{headMobile : isOpen == true}">
                    <div class="head-logo">
                        <a href="/"><img src="@/assets/img/icon/logo.png" alt=""></a>
                    </div>
                    <div class="head-nav">
                        <ul>
                            <li><router-link to="/about-us">{{ $t("nav.about") }}</router-link></li>
                            <li @mouseover="isVisible = true" @mouseleave="isVisible = false">
                                <a>
                                    <p>{{ $t("nav.product") }}</p>
                                    <img v-if="isVisible" src="@/assets/img/icon/arr-category2.png" alt="">
                                    <img v-else  src="@/assets/img/icon/arrow-blue.png" alt="">
                                </a>
                                <div class="head-dropdown m-none" v-if="isVisible" @mouseleave="isVisible = false">
                                    <li v-for="ct in cat" :key="ct.id" v-show="ct.type == 'products'"><router-link class="px-12-500" :to="`/catalog/${ct.id}`">{{ ct.title }}</router-link></li>
                                </div>
                            </li>
                            <li @mouseover="isVisible1 = true" @mouseleave="isVisible1 = false">
                                <a>
                                    <p>{{ $t("nav.service") }}</p>
                                    <img v-if="isVisible1" src="@/assets/img/icon/arr-category2.png" alt="">
                                    <img v-else  src="@/assets/img/icon/arrow-blue.png" alt="">
                                </a>
                                <div class="head-dropdown service-dropdown m-none" v-if="isVisible1" @mouseleave="isVisible1 = false">
                                    <li v-for="ct in cat" :key="ct.id" v-show="ct.type == 'services'"><router-link class="px-12-500" :to="`/catalog/${ct.id}`">{{ ct.title }}</router-link></li>
                                </div>
                            </li>
                            <li><router-link to="/photo-gallery">{{ $t("nav.gallery") }}</router-link></li>
                            <li><router-link to="/certificates">{{ $t("nav.license") }}</router-link></li>
                            <li><router-link to="/contacts">{{ $t("nav.contact") }}</router-link></li>
                        </ul>
                    </div>
                    <div class="head-right">
                        <div class="head-search">
                            <img @click="activeSearch = true" src="@/assets/img/icon/search.png" alt="">
                            <transition name="fade">
                                <div v-if="activeSearch" class="head-search__input">
                                    <input :placeholder="$t('search')+'...'" ref="search" type="text" @keyup.enter="onPressEnter" v-model="search_input" @keyup="searchHandle($event)">
                                    <!-- <img src="@/assets/img/icon/search.png" alt=""> -->
                                    <!-- <img @click="activeSearch = false" src="@/assets/img/icon/close.png" alt=""> -->
                                </div>
                            </transition>
                            <transition name="fade">
                                <div class="dropdown-res" v-if="activeSearch">
                                    <div v-if="isResult">
                                        <div class="search-result">
                                            <div class="search-result__wrap" v-if="SEARCH_RESULT.data.length">
                                                <router-link
                                                :to="{
                                                    path: `/card/${result.slug}`,
                                                }"
                                                v-for="result in SEARCH_RESULT.data"
                                                :key="result.id"
                                                @click="activeSearch = false"
                                                >
                                                <span v-html="result.title"></span>
                                                </router-link>
                                            </div>
                                            <div class="search-result__wrap" v-else-if="!SEARCH_RESULT.data.length">
                                                {{$t("nores")}}!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div class="head-lang">
                            <select name="" id="" v-model="lang" @change="handleChange($event)">
                                <option value="kz">KZ</option>
                                <option selected value="ru">RU</option>
                                <option value="en">EN</option>
                            </select>
                        </div>
                        <div @click="openMenu" class="burger-menu d-none">
                            <img src="@/assets/img/icon/burger.svg" alt="">
                        </div>
                    </div>
                </div>
                <div v-if="isOpen" class="head-mobile d-none">
                    <div class="head-mobile__close">
                        <img @click="isOpen = false" src="@/assets/img/icon/close.png" alt="">
                    </div>
                    <div class="head-mobile__nav">
                        <ul>
                            <li><a href="/about-us">{{ $t("nav.about") }}</a></li>
                            <li><a href="/catalog">{{ $t("nav.product") }}</a></li>
                            <li><a href="/catalog">{{ $t("nav.service") }}</a></li>
                            <li><a href="/photo-gallery">{{ $t("nav.gallery") }}</a></li>
                            <li><a href="/certificates">{{ $t("nav.license") }}</a></li>
                            <li><a href="/contacts">{{ $t("nav.contact") }}</a></li>
                        </ul>
                    </div>
                    <div class="head-mobile__lang">
                        <select name="" id="" v-model="lang" @change="handleChange($event)">
                            <option value="kz">KZ</option>
                            <option selected value="ru">RU</option>
                            <option value="en">EN</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activeSearch" @click="activeSearch = false" class="visible-search"></div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex';
export default {
data() {
    const lang = localStorage.getItem("lang") || "ru";
    return {
        cat: [],
        lang: lang,
        isOpen: false,
        isVisible: false,
        isVisible1: false,
        activeSearch: false,
        isResult: false
    }
},
created() {
    this.getCategory();
},
methods: {
    ...mapActions(["GET_SEARCH_RESULT"]),
    async searchHandle(e) {
      let q = e.target.value;
      if (q.length >= 3) {
        await this.GET_SEARCH_RESULT({ search: q, lang: localStorage.getItem("lang") }).then(() => {
          this.isResult = true;
        //   console.log('is result true!');
        //   console.log(this.SEARCH_RESULT.data.length);
        });
      } else {
        this.isResult = false;
      }
    },
    openMenu() {
        this.isOpen = true
    },
    closeSearch() {
        this.activeSearch = false
    },
    handleChange(event) {
        localStorage.setItem("lang", event.target.value)
        window.location.reload();
    },
    getCategory() {
        axios.get('categories')
        .then(response => {
            this.cat = response.data.data;
        })
        .catch (e=> {
            this.errors.push(e)
        })
    }
},
    computed: {
        ...mapGetters(['SEARCH_RESULT'])
    },
    watch: {
        toggle(e) {
            if (!e) {
                this.isResult = false;
                this.$refs.search.value = null;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/header.scss';
</style>