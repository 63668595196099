<template>
    <div class="foot">
        <div class="container">
            <div class="foot-wrap">
                <!-- {{ contact.terms.link }} -->
                <div class="foot-logo">
                    <a href="/"><img src="../../assets/img/icon/logo-footer.png" alt=""></a>
                </div>
                <div class="foot-info">
                   <div class="foot-info__left">
                        <h3 class="foot__title">{{ $t("info") }}</h3>
                        <div class="foot__nav">
                            <ul>
                                <li><a href="/about-us">{{ $t("nav.about") }}</a></li>
                                <li><a href="/catalog">{{ $t("nav.product") }}</a></li>
                                <li><a href="/catalog">{{ $t("nav.service") }}</a></li>
                                <li><a href="/photo-gallery">{{ $t("nav.gallery") }}</a></li>
                                <li><a href="/certificates">{{ $t("nav.license") }}</a></li>
                                <li><a href="/contacts">{{ $t("nav.contact") }}</a></li>
                            </ul>
                        </div>
                   </div>
                    <div class="foot-social__wrap d-none">
                        <div class="foot-social" v-for="ft in contact.socials" :key="ft.id">
                            <a :href="ft.link" target="_blank"><img :src="'https://admin.big-stroyservice.kz/' + ft.image" alt=""></a>
                        </div>
                    </div>
                </div>
                <div class="foot-contact">
                    <h3 class="foot__title">{{ $t("nav.contact") }}</h3>
                    <div class="foot__nav">
                        <ul>
                            <li v-for="ft in contact.contacts" :key="ft.id">
                                <span><img :src="'https://admin.big-stroyservice.kz/' + ft.image_1" alt=""></span> 
                                <a :href="ft.link" v-html="ft.title"></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="foot-graphic">
                    <h3 class="foot__title">{{ $t("graph") }}</h3>
                    <p v-for="ft in contact.workingHours" :key="ft.id"><span v-html="ft.title"></span></p>
                    <div class="foot-social__wrap m-none">
                        <div class="foot-social" v-for="ft in contact.socials" :key="ft.id">
                            <a :href="ft.link" target="_blank"><img :src="'https://admin.big-stroyservice.kz/' + ft.image" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="foot-line"/>
        <div class="container">
            <div class="foot-btm">
                <div class="foot-btm__card">(c) 2023 Big Construction</div>
                <a v-for="tr in contact.terms" :key="tr.id" :href="'https://admin.big-stroyservice.kz/' + tr.link"><img src="../../assets/img/icon/document-text.png" alt="">{{ $t("policy") }}</a>
                <a v-for="tr in contact.policy" :key="tr.id" :href="'https://admin.big-stroyservice.kz/' + tr.link"><img src="../../assets/img/icon/document-text.png" alt="">{{ $t("term") }}</a>
            </div>
        </div>
        <div class="foot-vidget d-none">
            <div class="container">
                <div class="foot-vidget__wrap">
                    <div class="foot-vidget__wrap-card">
                        <a href="/">
                            <img src="@/assets/img/icon/home.svg" alt="">
                            <p>{{ $t("main") }}</p>
                        </a>
                    </div>
                    <div class="foot-vidget__wrap-card">
                        <a href="/category">
                            <img src="@/assets/img/icon/box.svg" alt="">
                            <p>{{ $t("nav.product") }}</p>
                        </a>
                    </div>
                    <div class="foot-vidget__wrap-card">
                        <a href="/service">
                            <img src="@/assets/img/icon/service.svg" alt="">
                            <p>{{ $t("nav.service") }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex'
export default {
    data() {
        return {
            contact: [],
        }
    },
    created() {
        this.getContact()
    },
    methods: {
        getContact() {
            axios.get('page/contacts')
            .then(response => {
                this.contact = response.data.data;
            })
            .catch(e=> {
                this.errors.push(e)
            })
        }
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/footer.scss';
</style>