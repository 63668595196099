<template>
    <div class="tab-items">
      <ul class="tab-list">
        <li v-for="(tab, id) in tabItems" :key="tab" class="tab-item">
          <div
            :class="{ active: tab === selected }"
            class="tab-link blue-md-text"
          >
            <input type="radio" name="tab" id="" v-model="type" :value="id">
            <label for="tab"> {{ tab }} </label>
          </div>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        type: 0,
      }
    },
    props: {
      tabItems: {
        type: Array,
        required: true,
      },
      selected: {
        type: String,
        required: true,
      },
    },
    watch: {
      type() {
        this.$emit("setTab", 'tab', this.type);
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @mixin mobile {
    @media (min-width: 320px) and (max-width: 424px) {
      @content;
    }
  }
  @mixin tablet {
    @media (min-width: 425px) and (max-width: 768px) {
      @content;
    }
  }
  @mixin tablet-1024 {
    @media (min-width: 769px) and (max-width: 1023px) {
      @content;
    }
  }
  @mixin desktop {
    @media (min-width: 1024px) and (max-width: 1440px) {
      @content;
    }
  }
  div {
    padding: 0;
  }
  .tab {
    &-items {
      width: 100%;
      @include desktop() {
        // overflow-y: scroll;
      }
    }
    &-item {
      list-style: none;
      z-index: 111;
    }
  }
  .tab-list {
    display: flex;
    align-items: center;
    padding-left: 0;
    gap: 0;
    z-index: 111;
  }
  .tab-link {
    color: #132E59;
    display: flex;
    align-items: center;
    margin-right: 22px;
    input {
      margin-right: 10px;
    }
    &.active {
    }
  }
  </style>
  