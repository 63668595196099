<template>
    <div class="container content">
        <BreadCrumbs :links="links"/>
        <div class="license">
            <h4 class="page-title">{{ $t("nav.license") }}</h4>
            <div class="license-items">
                <img v-for="cf in cert" :key="cf.id" :src="'https://admin.big-stroyservice.kz/' + cf.image_url" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import BreadCrumbs from '@/components/ui/breadCrumbs.vue';
import {useHead} from "@vueuse/head"
import { computed, reactive } from "vue"

export default {
    components: { BreadCrumbs },
    data() {
        return {
            cert: [],
            meta: "",
            links: [
                {
                    title: this.$t("main"),
                    url: "/",
                },
                {
                    title: this.$t("nav.license"),
                    url: "/certificates",
                },
            ],
        }
    },
    setup() {
        const siteData = reactive({
        title: localStorage.getItem('meta_title') || 'Сертификаты и лицензии',
        description: localStorage.getItem('meta_description') || 'Сертификаты и лицензии',
    })
    useHead({
        meta: [
            {
                name:  computed(() => siteData.title),
                content: computed(() => siteData.description),
            },
        ],
    })
    },
    async created() {
       await axios.get('page/certificates')
        .then(response => {
            this.cert = response.data.certificates.data
            this.meta = response.data
        })
        .catch(e => {
            this.errors.push(e)
        })
        localStorage.setItem('meta_title',this.meta.meta_title)
        localStorage.setItem('meta_description',this.meta.meta_description)
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
.license {
    &-items {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        img {
            width: 19%;
            min-height: 328px;
            max-height: 432px;
            margin: 20px 15px 0 0;
            object-fit: cover;
        }
    }
}
@media (max-width: 1666px) {
    .license {
        &-items {
            img {
                margin: 20px 10px 0 0;
            }
        }
    }
}
@media (max-width: 920px) {
    .license {
        &-items {
            justify-content: space-between;
            img {
                width: 45%;
                margin: 10px 0 0 0;
            }
        }
    }
}
</style>