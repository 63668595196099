<template>
    <div class="container content" v-if="product">
        <div class="page-title">{{ $t("orderForm") }}</div>
        <p style="display: none; height: 0;">
            {{ products[0].total_price = product.price*piece }}
            {{ total_price = product.price*piece }}
            {{ products[0].id = product.id }}
            {{ products[0].quantity = this.piece }}
        </p>
        <div class="order">
            <div class="order-left">
                <PersonalTab
                    :tabItems="tabItems"
                    @setTab="selectNewsTab"
                    :model="false"
                    :selected="selectedNewsTab"
                ></PersonalTab>
                <tabBlock
                class="feed2"
                :isSelected="person_type === 0"
                >
                <form @submit.prevent="sendOrder" class="order-inner">
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("payType") }}:</p>
                        <select required v-model="method_payment" onfocus='this.size=4;' onblur='this.size=0;' onchange='this.size=1; this.blur();' name="" id="" class="select">
                            <option selected disabled value="">{{ $t("payType") }}</option>
                            <option value="0">{{ $t("bank") }}</option>
                            <option value="1">{{ $t("nal") }}</option>
                            <option value="2">{{ $t("cash") }}</option>
                        </select>
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("delType") }}:</p>
                        <select required v-model="method_delivery" onfocus='this.size=3;' onblur='this.size=0;' onchange='this.size=1; this.blur();' name="" id="" class="select">
                            <option selected disabled value="">{{ $t("delType") }}</option>
                            <option value="transport">{{ $t("transport") }}</option>
                            <option value="delivery_pickup">{{ $t("delSelf") }}</option>
                        </select>
                        <select required onfocus='this.size=5.5;' onblur='this.size=0;' onchange='this.size=1; this.blur();' name="" id="" class="select">
                            <option selected disabled value="">{{ $t("cityType") }}</option>
                            <option style="padding-bottom: 5px;" v-for="ct in city" :key="ct.id" :value="ct.id">{{ ct.title }}</option>
                        </select>
                        <input v-model="address" autocomplete="address-line1" required class="input" type="text" id="address-one" name="address-one" :placeholder="$t('address')">
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("clientInfo") }}:</p>
                        <input v-model="full_name" autocomplete="on" required class="input" type="text" name="name" :placeholder="$t('surname')">
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("tel") }}:</p>
                        <input v-model="phone" class="input" type="text" name="phone" required v-mask="'+7(###)-###-##-##'" placeholder="+ 7 (___) ___-___-___">
                        <input v-model="email" class="input" type="text" name="email" required placeholder="E-mail">
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("com") }}:</p>
                        <textarea required v-model="comment" class="input" name="" id="" cols="30" rows="10" :placeholder="$t('moreCom')"></textarea>
                    </div>
                    <div class="order-check">
                        <input type="checkbox" for="check" id="check">
                        <label for="check" class="sm-desc">{{ $t("sendPay") }}</label>
                    </div>
                    <div class="order-sum">
                        <p>{{ $t("pay") }}</p>
                        <div class="price">{{ formatPrice(product.price*piece) }}</div>
                    </div>
                    <div class="order-btn">
                        <button class="button" @submit.prevent="sendOrder">{{ $t("sendOrder") }}</button>
                    </div>
                    <div class="order-policy sm-desc">
                        {{ $t("pol1") }} <a v-for="ct in contact.policy" :key="ct.id" :href="'https://admin.big-stroyservice.kz/' + ct.link">{{ $t("pol2") }}</a> 
                        {{ $t("pol3") }}
                        <a v-for="ct in contact.terms" :key="ct.id" :href="'https://admin.big-stroyservice.kz/' + ct.link">{{ $t("pol4") }}</a>.
                    </div>
                </form>
            </tabBlock>
            <tabBlock
            class="feed2"
                :isSelected="person_type === 1"
            >
                <form @submit.prevent="sendOrder" class="order-inner">
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("payType") }}:</p>
                        <select required v-model="method_payment" onfocus='this.size=4;' onblur='this.size=0;' onchange='this.size=1; this.blur();' name="" id="" class="select">
                            <option selected disabled value="">{{ $t("payType") }}</option>
                            <option value="0">{{ $t("bank") }}</option>
                            <option value="1">{{ $t("nal") }}</option>
                            <option value="2">{{ $t("cash") }}</option>
                        </select>
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("delType") }}:</p>
                        <select required v-model="method_delivery" onfocus='this.size=3;' onblur='this.size=0;' onchange='this.size=1; this.blur();' name="" id="" class="select">
                            <option selected disabled value="">{{ $t("delType") }}</option>
                            <option value="transport">{{ $t("transport") }}</option>
                            <option value="delivery_pickup">{{ $t("delSelf") }}</option>
                        </select>
                        <select name="" id="" class="select" onfocus='this.size=5;' onblur='this.size=0;' onchange='this.size=1; this.blur();' required>
                            <option selected disabled value="">{{ $t("cityType") }}</option>
                            <option style="padding-bottom: 5px;" v-for="ct in city" :key="ct.id" value="">{{ ct.title }}</option>
                        </select>
                        <input class="input" autocomplete="address-line1" required type="text" id="address-one" name="address-one" v-model="address" :placeholder="$t('address')">
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("comInfo") }}:</p>
                        <input v-model="company_name" class="input" autocomplete="organization" required type="text" name="organization" id="organization" :placeholder="$t('infoCom')">
                        <input v-model="full_name" class="input" type="text" name="name" :placeholder="$t('surname')" required>
                        <input v-model="recipient_position" autocomplete="position-name" required class="input" type="text" name="position-name" id="position-name" :placeholder="$t('position')">
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("tel") }}:</p>
                        <input required v-model="phone" class="input" type="text" name="phone" v-mask="'+7(###)-###-##-##'" placeholder="+ 7 (___) ___-___-___">
                        <input required v-model="email" class="input" type="text" name="email" placeholder="E-mail">
                    </div>
                    <div class="order-inner__pay">
                        <p class="blue-md-text">{{ $t("com") }}:</p>
                        <textarea required v-model="comment " class="input" name="" id="" cols="30" rows="10" :placeholder="$t('moreCom')"></textarea>
                    </div>
                    <div class="order-check">
                        <input type="checkbox" for="check" id="check">
                        <label for="check" class="sm-desc">{{ $t("sendPay") }}</label>
                    </div>
                    <div class="order-sum">
                        <p>{{ $t("pay") }}</p>
                        <div class="price">{{ formatPrice(product.price*piece) }}</div>
                    </div>
                    <div class="order-btn">
                        <button @submit.prevent="sendOrder" class="button">{{ $t("sendOrder") }}</button>
                    </div>
                    <div class="order-policy">
                        {{ $t("pol1") }}<a v-for="ct in contact.policy" :key="ct.id" :href="'https://admin.big-stroyservice.kz/' + ct.link">{{ $t("pol2") }}</a> 
                        {{ $t("pol3") }}
                    <a v-for="ct in contact.terms" :key="ct.id" :href="'https://admin.big-stroyservice.kz/' + ct.link">{{ $t("pol4") }}</a>.
                    </div>
                </form>
            </tabBlock>
            </div>
            <div class="order-right">
                <h4 class="md-title">{{ $t("inOrder") }} {{ piece }} {{ $t("inOrder2") }}</h4>
                <div class="order-right__wrap">
                    <div class="card-wrap">
                        <img :src="cdn + product.image" alt="">
                        <div class="order-right__wrap-name">
                            <h5 class="px-18">{{ product.title }}</h5>
                            <p class="sm-text">{{ $t("code") }}: {{ product.vendor_code }} <br> <span v-if="product.status_code == 1">{{ $t("stock") }}</span><span v-else>{{ $t("none") }}</span> <br> {{ $t("price") }}: {{ formatPrice(product.price*piece) }}</p>
                        </div>
                    </div>
                    <div class="order-right__wrap-piece m-none">
                        <p class="sm-text">{{ $t("piece") }}</p>
                        <div class="piece">
                            <button :disabled="disableMinus" @click="minus">-</button>
                            <span class="sm-desc">{{ piece }} {{ $t("pc") }}</span>
                            <button :disabled="disablePlus" @click="plus">+</button>
                        </div>
                    </div>
                    <div class="price m-none">
                        <p class="sm-text">{{ $t("price") }}</p>
                        <h5 class="px-18">{{ formatPrice(product.price*piece) }}</h5>
                    </div>
                    <img class="m-none delete-img" @click="deleteOrder" src="../assets/img/icon/delete.svg" alt="">
                    <div class="order-right__wrap__btm d-none">
                        <div class="order-right__wrap-piece">
                        <p class="sm-text">{{ $t("piece") }}</p>
                        <div class="piece">
                            <button :disabled="disableMinus" @click="minus">-</button>
                            <span class="sm-desc">{{ piece }} {{ $t("pc") }}</span>
                            <button :disabled="disablePlus" @click="plus">+</button>
                        </div>
                    </div>
                    <div class="price">
                        <p class="sm-text">{{ $t("price") }}</p>
                        <h5 class="px-18">{{ formatPrice(product.price*piece) }}</h5>
                    </div>
                    <img class="delete-img" @click="deleteOrder" src="../assets/img/icon/delete.svg" alt="">
                    </div>
                </div>
                <div class="order-right__total">
                    <h4 class="px-18-400">{{ $t("pay") }}</h4>
                    <p class="px-18">{{ formatPrice(product.price*piece) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonalTab from "../components/tab/PersonalTab.vue";
import tabBlock from "../components/tab/tabBlock.vue";
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
export default {
components: {PersonalTab, tabBlock},
data() {
    return {
        selectedNewsTab: this.$t("physical"),
        tabItems: [this.$t("physical"), this.$t("law")],
        piece: 1,
        disableMinus: false,
        disablePlus: false,
        city: "",
        method_payment: null,
        method_delivery: null,
        city_id: 1,
        address: null,
        full_name: null,
        phone: null,
        comment: null,
        product: null,
        email: null,
        person_type: 0,
        company_name: null,
        recipient_position: null,
        products: [
            {
                id: null,
                quantity: null,
                total_price: null
            },
        ],
        total_price: null,
        contact: [],
        price: 140000,
    }
},
created() {
    this.getCity();
    this.getContact();
},
computed: {
    ...mapGetters(["GET_ORDER_PRODUCT"]),
    ...mapState(["order_product", "cdn"]),
},
mounted() {
    axios.get(`products/product?lang=ru&slug=${JSON.parse(localStorage.getItem('product'))}`)
        .then(response => {
            this.product = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
},
methods: {
    formatPrice: price => `${(+price).toLocaleString()} ₸`,
    sendOrder() {
        this.form = {
            method_payment: this.method_payment,
            method_delivery: this.method_delivery,
            comment: this.comment,
            full_name: this.full_name,
            city_id: this.city_id,
            phone: this.phone,
            address: this.address,
            person_type: this.person_type,
            total_price: this.total_price,
            products: this.products,
            company_name: this.company_name,
            recipient_position: this.recipient_position,
            email: this.email,
        };
        axios.post('orders', this.form)
        .then(response => {
            if(response.status == 200) {
                alert('Ваша заявка отправлена успешно!');
                this.$router.push('/catalog')
                this.method_delivery = "";
                this.method_payment = "",
                this.comment = "",
                this.full_name = "",
                this.phone = "",
                this.address = "",
                this.city_id = 1,
                this.person_type = "",
                this.email = "",
                this.total_price = "",
                this.company_name = "",
                this.recipient_position = ""
            }
            else {
                // console.log(response.status);
                alert('error!')
            }
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    getContact() {
        axios.get('page/contacts')
        .then(response => {
            this.contact = response.data.data;
        })
        .catch(e=> {
            this.errors.push(e)
        })
    },
    selectNewsTab(tab, id) {
        this.person_type = id
      this.selectedNewsTab = tab;
    },
    getCity() {
        axios.get('cities')
        .then(response => {
            this.city = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    minus() {
        if(this.piece > 1) {
            this.piece--;
        }
        else {
            this.disableMinus == true
        }
    },
    plus() {
        this.piece++;
    },
    deleteOrder() {
        this.$router.push('/catalog')
    }
}
}
</script>


<style lang="scss" scoped>
.card-wrap {
    display: flex;
    img {
        width: 122px;
        height: 100px;
        object-fit: cover;
    }
}
.delete-img {
    cursor: pointer;
}
.order {
    padding-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &-left {
        width: 40%;
    }
    &-right {
        width: 55%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(19, 46, 89, 0.15);
        border-radius: 10px;
        padding: 30px 18px;
        &__wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            padding: 22px 8px;
            border: 1px solid #EFEFEF;
            border-radius: 10px;
            &__btm {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            img {
                margin-right: 8px;
            }
            &-name {
                p {
                    margin-top: 17px;
                }
            }
            &-piece, .price {
                p {
                    margin-bottom: 10px;
                }
            }
            &-piece {
                button {
                    background-color: #FFFFFF;
                    border: none;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 4px 18px;
                    color: #2C3131;
                }
                .piece {
                    display: flex;
                    align-items: center;
                    border: 0.75px solid #A9A9A9;
                    border-radius: 8px;
                    padding: 2px;
                    span {
                        padding: 4px 11px;
                        border-left: 1px solid #A9A9A9;
                        border-right: 1px solid #A9A9A9;
                    }
                }
            }
        }
        &__total {
            display: flex;
            justify-content: space-between;
            padding: 38px 0;
        }
    }
    &-inner {
        width: 100%;
        &__pay {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            p {
                color: #132E59;
                margin-bottom: 10px;
            }
            select, input {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    &-check {
        display: flex;
        align-items: center;
        margin: 16px 0 30px 0;
        input {
            width: 16px;
            height: 16px;
            box-shadow: 0px 4px 9px rgba(0, 80, 190, 0.4);
            border-radius: 2px;
        }
        label {
            margin-left: 9px;
        }
    }
    &-sum {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #132E59;
        .price {
            font-weight: 600;
        }
    }
    &-btn {
        margin: 30px 0 15px 0;
    }
}
@media (max-width: 920px) {
    .order {
        flex-direction: column-reverse;
        &-left {
            width: 100%;
            margin-top: 65px;
        }
        &-right {
            width: 100%;
            &__wrap {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}
</style>