<template>
    <div class="container">
        <div class="level">
            <h4 class="title-blue">{{ $t("level") }}</h4>
            <div class="level-wrap">
                <div class="level-box m-none" v-for="lv in level.homePageWorkStagesLists" :key="lv.id">
                    <div class="level-box__card" >
                        <!-- <img src="../../assets/img/level2.png"> -->
                        <img :src="'https://admin.big-stroyservice.kz/' + lv.image">
                        <div class="line"></div>
                        <p v-if="lv" v-html="lv.title"></p>
                    </div>
                </div>
                <div class="level-card__wrap d-none">
                    <div class="level-card" v-for="lv in level.homePageWorkStagesLists?.slice(0,2)" :key="lv.id">
                        <img :src="'https://admin.big-stroyservice.kz/' + lv.image">
                        <p v-if="lv" v-html="lv.title"></p>
                    </div>
                    <div class="line-box"></div>
                </div>
                <div class="level-card__wrap d-none">
                    <div class="level-card" v-for="lv in level.homePageWorkStagesLists?.slice(2,4)" :key="lv.id">
                        <img :src="'https://admin.big-stroyservice.kz/' + lv.image">
                        <p v-if="lv" v-html="lv.title"></p>
                    </div>
                    <div class="line-box2"></div>
                </div>
                <div class="level-card__wrap d-none">
                    <div class="level-card" v-for="lv in level.homePageWorkStagesLists?.slice(4,5)" :key="lv.id">
                        <img :src="'https://admin.big-stroyservice.kz/' + lv.image">
                        <p v-if="lv" v-html="lv.title"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex'
export default {
    data() {
        return {
            level: [],
        }
    },
    created() {
        axios.get('page/home')
        .then(response => {
            this.level = response.data.data
        })
        .catch(e => {
            this.errors.push(e)
        })
    },
    computed: {
        ...mapState(['cdn'])
    }
}
</script>

<style lang="scss" scoped>
.line-box {
    position: absolute;
    width: 55%;
    top: 55%;
    left: 40%;
    &::before {
        z-index: 0;
        content: "";
        display: block;
        position: absolute;
        border-bottom: 1px dashed #828282;
        top: calc(23% - 1px);
        width: calc(45% - 20px);
    }
}
.line-box2 {
    position: absolute;
    width: 55%;
    top: 55%;
    left: 40%;
    &::before {
        z-index: 0;
        content: "";
        display: block;
        position: absolute;
        border-bottom: 1px dashed #828282;
        top: calc(23% - 1px);
        width: calc(45% - 20px);
    }
}
.level {
    padding-bottom: 85px;
    &-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        p {
            text-align: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.02em;
            color: #132E59;
        }
        img {
            width: 110px;
            height: 110px;
            margin-bottom: 14px;
        }
        &__wrap {
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            &:last-child {
                justify-content: center;
            }
        }
    }
    &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        margin: 0 auto;
        &::before {
			z-index: 0;
			content: "";
			display: block;
			position: absolute;
			// height: 2px;
            border-bottom: 2px dashed #828282;
			top: calc(44% - 1px);
			width: calc(100% - 20px);
		}
    }
    &-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        &__card {
            display: flex;
            flex-direction: column;
            align-items: center;
            // &::before {
            //     z-index: 0;
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     border-bottom: 2px dashed #828282;
            //     top: calc(23% - 1px);
            //     width: calc(100% - 20px);
            // }
            img {
                width: 132px;
                margin-bottom: 15px;
                z-index: 11;
            }
            p {
                width: 132px;
                text-align: center;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.02em;
                color: #132E59;
            }
        }
        &:last-child {
            .horizontal-line {
                display: none;
            }
        }
    }
}
@media (max-width: 920px) {
    .level {
        &-wrap {
            justify-content: space-around;
            flex-wrap: wrap;
            margin-top: 20px;
            &::before {
                display: none;
            }
        }
    }
}

</style>