<template>
    <div class="container content">
        <div class="agree">
            <h4 class="page-title" style="font-weight: 500" v-html="policy.termsPageTitle.desc"></h4>
            <div class="agree-desc" v-html="policy.termsPageText.content">
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            policy: [],
        }
    },
    created() {
        this.getPolicy();
    },
    methods: {
        getPolicy() {
            axios.get('page/terms')
            .then(response => {
                this.policy = response.data.data
            })
            .catch(e=> {
                this.errors.push(e);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.agree {
    &-desc, h5 {
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
        color: #132E59;
    }
    &-desc {
        margin-top: 35px;
    }
}
@media (max-width: 920px) {
    .agree {
        &-desc {
            font-size: 14px;
            line-height: 25px;
        }
    }
}
</style>