import { createApp } from 'vue'
import App from './App.vue'
import VueRouter from './router'
import { createHead } from "@vueuse/head"
import './assets/styles/main.scss'
import { useVuelidate } from '@vuelidate/core'
import VueTheMask from 'vue-the-mask'
import axios from 'axios'
import store from './store'
import i18n from './i18n'
// import lightgallery from 'lightgallery.js'
const lang = localStorage.getItem('lang') || 'ru';
const head = createHead()
axios.defaults.baseURL = 'https://admin.big-stroyservice.kz/api/';
axios.defaults.params = {
  'lang': lang,
};

const app = createApp(App)
app.use(VueRouter)
app.use(useVuelidate)
app.use(i18n)
app.use(store)
app.use(VueTheMask)
app.use(head)
// app.use(lightgallery)
// app.component("star-rating", VueStarRating.default)
app.mount('#app')
