<template>
        <div class="hits-cards__item" v-if="products">
            <router-link :to="{
                path: `/card/${products.slug}`
                }">
                <img :src="url + products.image" alt="">
            </router-link>
            <div class="box">
                <div class="box-card">
                    <h4 class="name blue-md-text" v-html="products.title"></h4>
                    <p class="price blue-md-text">{{ formatPrice(products.price) }}</p>
                    <p class="status blue-md-text" v-if="products.status_code == 1"> {{ $t("stock") }}</p>
                    <p class="status blue-md-text" v-else>{{ $t("none") }}</p>
                </div>
                <router-link to="/order"> <button class="button" @click="toLocal">{{ $t("orderBtn") }} </button> </router-link>
            </div>
        </div>
</template>

<script>
// import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            // product: [],
            url: 'https://admin.big-stroyservice.kz/'
        }
    },
    props: [ "products" ],
    created() {
    },
    methods: {
        ...mapActions(["LOCALSTORAGE_PRODUCT"]),
        // getProduct() {
        //     axios.get('products')
        //     .then( response=> {
        //         this.product = response.data.data;
        //     })
        //     .catch(e=> {
        //         this.errors.push(e);
        //     })
        // },
        async toLocal() {
            console.log('a', this.products);
            await this.LOCALSTORAGE_PRODUCT(this.products)
        },
        formatPrice: price => `${(+price).toLocaleString()} ₸`,
    },
    watch: {
        $route() {
            // this.getProduct()
        }
    }
}
</script>

<style lang="scss" scoped>
.hits {
    &-cards {
        &__item {
            background: #FFFFFF;
            box-shadow: 0px 4px 24px rgba(19, 46, 89, 0.3);
            border-radius: 10px;
            width: 19%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            button {
                a {
                    color: #F9AC3A;
                }
                &:hover {
                    a {
                        color: #132E59;
                    }
                }
            }
            img {
                border-radius: 10px 10px 0px 0px;
                height: 150px;
                width: 100%;
                object-fit: cover;
            }
            .box {
                padding: 10px 10px 25px 10px;
                height: 180px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                h4, .price {
                    color: #132E59;
                }
                .price {
                    padding: 10px 0;
                }
                .status {
                    color: #5BC6F1;
                    padding-bottom: 10px;
                }
                h4 {
                    height: 30px;
                    -ms-text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -ms-line-clamp: 2;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    display: -webkit-box;
                    display: box;
                    word-wrap: break-word;
                    -webkit-box-orient: vertical;
                    box-orient: vertical;
                }
            }
        }
    }
}
@media (max-width: 920px) {
    .hits {
        &-cards {
            &__item {
                width: 100% !important;
            }
        }
    }
}
</style>