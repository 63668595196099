<template>
    <div class="container content" :class="{hidden: filterActive || sortActive}">
        <bread-crumbs :links="links" />
        <div class="catalog">
            <div v-if="filterActive" class="catalog-black d-none"></div>
            <div v-if="sortActive" class="catalog-black d-none"></div>
            <div class="catalog-top">
                <h1 class="page-title">{{ category_title }}</h1>
                <div class="catalog-top__sort m-none">
                    <p class="gray-14">{{ $t("sort") }}:</p>
                    <select v-model="sort_by" name="" id="" class="blue-md-text">
                        <option value="asc">{{ $t("prog") }}</option>
                        <option value="desc">{{ $t("reg") }}</option>
                    </select>
                </div>
                <div class="catalog-mobile d-none">
                    <button @click="filterActive = true"><img src="@/assets/img/icon/filter.png" alt="">{{ $t("filter") }}</button>
                    <div class="catalog-top__sort">
                        <p class="gray-14">{{ $t("sort") }}:</p>
                        <select v-model="sort_by" name="" id="" class="blue-md-text">
                            <option value="asc">{{ $t("prog") }}</option>
                            <option value="desc">{{ $t("reg") }}</option>
                        </select>
                    </div>
                </div>
                <transition :duration="{ enter: 500, leave: 800 }" name = "nested">
                <div v-if="filterActive" class="catalog-nav-mobile  d-none">
                    <div class="filter">
                        <div class="filter-top">
                            <h4 class="md-title">{{ $t("filter") }}</h4>
                            <img @click="filterActive = false" src="@/assets/img/icon/close.png" alt="">
                        </div>
                        <!-- <input type="range" min="0" max="100" step="1" v-model="value" @input="updateSlider" :style="{backgroundSize: backgroundSize}">
                        {{ value }} -->
                        <div class="filter d-none">
                            <div class="filter-available">
                                <input v-model="status_code" value="1" type="checkbox" name="stock" id="stock">
                                <label class="light-blue-16" for="stock">{{ $t("stock") }}</label>
                            </div>
                            <div>
                                <div class="filter-category">
                                    <h5 class="sm-title-500">{{ $t("price") }}</h5>
                                    <input  type="range" :min="0" :max="100000000" step="1000" v-model="price_range[1]"> 
                                    <div class="range-inputs">
                                        <input class="px15-gray"  v-model="price_range[0]" type="text">
                                        <input class="px15-gray"  v-model="price_range[1]" type="text">
                                    </div>
                                </div>
                                <div class="filter-category">
                                    <h5 class="sm-title-500">{{ $t("area") }} </h5>
                                    <input type="range" :min="0" :max="500" step="5" v-model="area_range[1]"> 
                                    <div class="range-inputs">
                                        <input class="px15-gray" v-model="area_range[0]" type="text">
                                        <input class="px15-gray" v-model="area_range[1]" type="text">
                                    </div>
                                </div>
                                <div class="filter-category">
                                    <h5 class="sm-title-500">{{ $t("height") }}</h5>
                                    <input type="range" :min="0" :max="400" step="4" v-model="height_range[1]"> 
                                    <div class="range-inputs">
                                        <input class="px15-gray" v-model="height_range[0]" type="text">
                                        <input class="px15-gray" v-model="height_range[1]" type="text">
                                    </div>
                                </div>
                                <div class="filter-category">
                                    <h5 class="sm-title-500">{{ $t("width") }}</h5>
                                    <input type="range" :min="0" :max="200" step="5" v-model="width_range[1]"> 
                                    <div class="range-inputs">
                                        <input class="px15-gray" v-model="width_range[0]" type="text">
                                        <input class="px15-gray" v-model="width_range[1]" type="text">
                                    </div>
                                </div>
                                <div class="filter-category">
                                    <h5 class="sm-title-500">{{ $t("long") }}</h5>
                                    <input type="range" :min="0" :max="10" step="1" v-model="long_range[1]"> 
                                    <div class="range-inputs">
                                        <input class="px15-gray" v-model="long_range[0]" type="text">
                                        <input class="px15-gray" v-model="long_range[1]" type="text">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="category-inputs">
                        <h5 class="blue-md-desc">{{ $t("country") }}</h5>
                        <div class="category-inputs__input" v-for="(item, id) in filter.countries" :key="id">
                            <input type="checkbox" name="input" :id="item.id" :value="item.id" v-model="countries_check" @change="check($event)">
                            <label :for="item.id" class="light-blue-16">{{ item.title }}</label>
                        </div>
                    </div>
                    <!-- <div class="category-inputs" v-for="(item, id) in filter.specifications" :key="id">
                        <h5 class="blue-md-desc">{{ item.title }}</h5>
                        <div class="category-inputs__input" v-for="(it, id) in item.specification_items" :key="id">
                            <input type="checkbox" name="input1" :id="it.id">
                            <label :for="it.id" class="light-blue-16">{{ it.title }}</label>
                        </div>
                    </div> -->
                    <div class="category-inputs" v-for="(item, id) in filter.specifications" :key="id">
                        <h5 class="blue-md-desc">{{ item.title }}</h5>
                        <div class="category-inputs__input" v-for="(it, id) in item.specification_items" :key="id">
                            <input v-model="specification_item_ids" :value="it.id" type="checkbox" name="input1" :id="it.id">
                            <label :for="it.id" class="light-blue-16">{{ it.title }}</label>
                        </div>
                    </div>
                    <div class="category-inputs">
                        <h5 class="blue-md-desc">{{ $t("electr") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_electricity" value="1" type="checkbox" name="input3" id="input3">
                            <label for="input3" class="light-blue-16">{{ $t("yes") }}</label>
                        </div>
                    </div>
                    <div class="category-inputs">
                        <h5 class="blue-md-desc">{{ $t("vent") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_ventilation" value="1" type="checkbox" name="input4" id="input4">
                            <label for="input4" class="light-blue-16">{{$t("yes")}}</label>
                        </div>
                    </div>
                    <div class="category-inputs">
                        <h5 class="blue-md-desc">{{ $t("water") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_water" value="1" type="checkbox" name="input5" id="input5">
                            <label for="input5" class="light-blue-16">{{$t("yes")}}</label>
                        </div>
                    </div>
                    <div class="category-inputs">
                        <h5 class="blue-md-desc">{{ $t("canal") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_canalization" value="1" type="checkbox" name="input6" id="input6">
                            <label for="input6" class="light-blue-16">{{$t("yes")}}</label>
                        </div>
                    </div>
                    <div class="filter-btns">
                        <button class="button" @click="defaulFilter">{{ $t("defaulFilter") }}</button>
                        <button class="button" @click="activeFilter">{{ $t("activeFilter") }}</button>
                    </div>
                </div>
            </transition>
            </div>
            <div class="catalog-content">
                <div class="catalog-nav">
                    <categoryPanel  @category_title="activeCategory" />
                    <div class="filter m-none">
                        <h4 class="md-title">{{ $t("filter") }}</h4>
                        <div class="filter-available">
                            <input v-model="status_code" value="1" type="checkbox" name="stock" id="stock">
                            <label class="light-blue-16" for="stock">{{ $t("stock") }}</label>
                        </div>
                        <div class="filter-category">
                            <h5 class="sm-title-500">{{ $t("price") }}</h5>
                            <VueSimpleRangeSlider
                                :min="0"
                                :max="100000000"
                                exponential
                                v-model="price_range"
                                >
                                <template #prefix="{}"></template>
                            </VueSimpleRangeSlider>
                            <div class="range-inputs">
                                <input class="px15-gray" disabled v-model="price_range[0]" type="text">
                                <input class="px15-gray" disabled v-model="price_range[1]" type="text">
                            </div>
                        </div>
                        <div class="filter-category">
                            <h5 class="sm-title-500">{{ $t("area") }} </h5>
                            <VueSimpleRangeSlider
                                :min="0"
                                :max="500"
                                exponential
                                v-model="area_range"
                                >
                                <template #prefix="{}"></template>
                            </VueSimpleRangeSlider>
                            <div class="range-inputs">
                                <input class="px15-gray" disabled v-model="area_range[0]" type="text">
                                <input class="px15-gray" disabled v-model="area_range[1]" type="text">
                            </div>
                        </div>
                        <div class="filter-category">
                            <h5 class="sm-title-500">{{ $t("height") }}</h5>
                            <VueSimpleRangeSlider
                                :min="0"
                                :max="400"
                                exponential
                                v-model="height_range"
                                >
                                <template #prefix="{}"></template>
                            </VueSimpleRangeSlider>
                            <div class="range-inputs">
                                <input class="px15-gray" disabled v-model="height_range[0]" type="text">
                                <input class="px15-gray" disabled v-model="height_range[1]" type="text">
                            </div>
                        </div>
                        <div class="filter-category">
                            <h5 class="sm-title-500">{{ $t("width") }}</h5>
                            <VueSimpleRangeSlider
                                :min="0"
                                :max="200"
                                exponential
                                v-model="width_range"
                                >
                                <template #prefix="{}"></template>
                            </VueSimpleRangeSlider>
                            <div class="range-inputs">
                                <input class="px15-gray" disabled v-model="width_range[0]" type="text">
                                <input class="px15-gray" disabled v-model="width_range[1]" type="text">
                            </div>
                        </div>
                        <div class="filter-category">
                            <h5 class="sm-title-500">{{ $t("long") }}</h5>
                            <VueSimpleRangeSlider
                                :min="0"
                                :max="10"
                                exponential
                                v-model="long_range"
                                >
                                <template #prefix="{}"></template>
                            </VueSimpleRangeSlider>
                            <div class="range-inputs">
                                <input class="px15-gray" disabled v-model="long_range[0]" type="text">
                                <input class="px15-gray" disabled v-model="long_range[1]" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="category-inputs m-none">
                        <!-- {{ countries_check }} -->
                        <h5 class="blue-md-desc">{{ $t("country") }}</h5>
                        <div class="category-inputs__input" v-for="(item, id) in filter.countries" :key="id">
                            <input type="checkbox" name="input" :id="item.id" :value="item.id" v-model="countries_check" @change="check($event)">
                            <label :for="item.id" class="light-blue-16">{{ item.title }}</label>
                        </div>
                    </div>
                    <div class="category-inputs m-none" v-for="(item, id) in filter.specifications" :key="id">
                        <h5 class="blue-md-desc">{{ item.title }}</h5>
                        <div class="category-inputs__input" v-for="(it, id) in item.specification_items" :key="id">
                            <input v-model="specification_item_ids" :value="it.id"  type="checkbox" name="input1" :id="it.id">
                            <label :for="it.id" class="light-blue-16">{{ it.title }}</label>
                        </div>
                    </div>
                    <div class="category-inputs m-none">
                        <h5 class="blue-md-desc">{{ $t("electr") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_electricity" value="1" type="checkbox" name="input3" id="input3">
                            <label for="input3" class="light-blue-16">{{ $t("yes") }}</label>
                        </div>
                    </div>
                    <div class="category-inputs m-none">
                        <h5 class="blue-md-desc">{{ $t("vent") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_ventilation" value="1" type="checkbox" name="input4" id="input4">
                            <label for="input4" class="light-blue-16">{{ $t("yes") }}</label>
                        </div>
                    </div>
                    <div class="category-inputs m-none">
                        <h5 class="blue-md-desc">{{ $t("water") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_water" value="1" type="checkbox" name="input5" id="input5">
                            <label for="input5" class="light-blue-16">{{ $t("yes") }}</label>
                        </div>
                    </div>
                    <div class="category-inputs m-none">
                        <h5 class="blue-md-desc">{{ $t("canal") }}</h5>
                        <div class="category-inputs__input">
                            <input v-model="is_canalization" value="1" type="checkbox" name="input6" id="input6">
                            <label for="input6" class="light-blue-16">{{ $t("yes") }}</label>
                        </div>
                    </div>
                    <div class="filter-btns m-none">
                        <button class="button" @click="defaulFilter">{{ $t("defaulFilter") }}</button>
                        <button class="button" @click="activeFilter">{{ $t("activeFilter") }}</button>
                    </div>
                </div>
                <div class="catalog-content__product" v-if="product.length > 0">
                    <div class="catalog-content__card">
                        <CardVue v-for="item in product" :products="item" :key="item.id" class="card"/>
                    </div>
                    <paginate
                        :page-count="total_page.last_page"
                        :click-handler="clickCallback"
                        class="className"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'className'"
                        :active-class="'paginate-active'"
                    >
                    </paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardVue from '@/components/product/CardVue.vue';
import categoryPanel from '@/components/product/category-panel.vue';
import breadCrumbs from '@/components/ui/breadCrumbs.vue';
import axios from 'axios'
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/css";
import { reactive, defineComponent, computed } from "vue";
import Paginate from "vuejs-paginate-next";
import {useHead} from "@vueuse/head";
// import DoubleRange from '@/components/DoubleRange.vue';
let min_area = 0;
let max_area = 100000;
let min_length = 0;
let max_length = 3000;
let min_width = 0;
let max_width = 8000;
let min_height = 0;
let max_height = 600;
export default defineComponent({
    components: {
        categoryPanel,
        CardVue,
        breadCrumbs,
        VueSimpleRangeSlider ,
        paginate: Paginate,
        // DoubleRange
    },
    data() {
        return {
            selectedPage: 1,
            value: 20,
            backgroundSize: '20% 100%',
            product: [],
            countries_check: [],
            specification_item_ids: [],
            status_code: false,
            is_electricity: false,
            price_range: [0, 100000000],
            area_range: [0, 1000],
            height_range: [0, 400],
            width_range: [0, 200],
            long_range: [0, 10],
            filterActive: false,
            sortActive: false,
            url: 'https://admin.big-stroyservice.kz/',
            minAngle: 10,
            maxAngle: 30,
            filter: [],
            min: 10,
            max: 210,
            minValue: 40,
            maxValue: 150,
            step: 5,
            totalSteps: 0,
            percentPerStep: 1,
            trackWidth: null,
            isDragging: false,
            sort_by: 'desc',
            pos: {
                curTrack: null
            },
            is_ventilation: false,
            is_water: false,
            is_canalization: false,
            meta: "",
            links: [
                    {
                        title: this.$t("main"),
                        url: "/",
                    },
                    {
                        title: this.$t("productService"),
                        url: "/catalog",
                    },
                    {
                        title: "",
                        url: ""
                    }
            ],
        }
    },
    async mounted() {
    },
    setup() {
        const siteData = reactive({
            title: localStorage.getItem('meta_title') || 'Товары',
            description: localStorage.getItem('meta_description') || 'Товары',
        })

        useHead({
            meta: [
                {
                    name:  computed(() => siteData.title),
                    content: computed(() => siteData.description),
                },
            ],
        })
        let state = reactive({  number: 10, range1: [`${min_area}`, `${max_area}`], range2: [`${min_length}`, `${max_length}`], range3: [`${min_width}`, `${max_width}`], range4: [`${min_height}`, `${max_height}`] });
        return { state };
    },
    async created() {
        this.getFilter();
        await axios.get(`products?page=${this.selectedPage}&category_id=${this.$route.fullPath.slice(9, 11)}&${this.$route.name == 'catalogId' ? 'subcategory_id='+this.$route.params.id+'&' : '' }country_ids=${[this.countries_check]}&specification_item_ids=${[this.specification_item_ids]}&${this.status_code ? 'status_code=' + 1 + '&': ''}&${this.is_electricity ? 'is_electricity=' + 1 + '&': ''}&${this.is_ventilation ? 'is_ventilation=' + 1 + '&': ''}&${this.is_water ? 'is_water=' + 1 + '&': ''}&${this.is_canalization ? 'is_canalization=' + 1 + '&': ''}sort_by=${this.sort_by}&min_price=${this.price_range[0]}&max_price=${this.price_range[1]}&min_area=${this.area_range[0]}&max_area=${this.area_range[1]}&min_length=${this.height_range[0]}&max_length=${this.height_range[1]}&min_width=${this.width_range[0]}&max_width=${this.width_range[1]}&min_height=${this.long_range[0]}&max_height=${this.long_range[1]}`)
        .then( response=> {
            this.product = response.data.products.data;
            this.total_page = response.data.products;
            this.meta = response.data
        })
        .catch(e=> {
            this.errors.push(e);
        })
        localStorage.setItem('meta_title',this.meta.meta_title)
        localStorage.setItem('meta_description',this.meta.meta_description)
    },
    methods: {
        activeCategory(category_title) {
            this.category_title = category_title
        this.getTranslate();
        },
        getTranslate() {
            this.links[2].title = this.category_title;
        },
        check: function(e) {
            if(e.target.checked) {
                console.log('checked!!');
                this.getProduct(this.countries_check)
            }
            else {
                console.log("load required data using service");
            }
        },
        activeFilter() {
            this.getProduct();
        },
        defaulFilter() {
            window.location.reload()
        },
        getFilter() {
            axios.get('filters')
            .then(response => {
                this.filter = response.data.data;
            })
            .catch( e=> {
                this.errors.push(e);
            })
        },
        getProduct() {
            axios.get(`products?page=${this.selectedPage}&category_id=${this.$route.fullPath.slice(9, 11)}&${this.$route.name == 'catalogId' ? 'subcategory_id='+this.$route.params.id+'&' : '' }country_ids=${[this.countries_check]}&specification_item_ids=${[this.specification_item_ids]}&${this.status_code ? 'status_code=' + 1 + '&': ''}&${this.is_electricity ? 'is_electricity=' + 1 + '&': ''}&${this.is_ventilation ? 'is_ventilation=' + 1 + '&': ''}&${this.is_water ? 'is_water=' + 1 + '&': ''}&${this.is_canalization ? 'is_canalization=' + 1 + '&': ''}sort_by=${this.sort_by}&min_price=${this.price_range[0]}&max_price=${this.price_range[1]}&min_area=${this.area_range[0]}&max_area=${this.area_range[1]}&min_length=${this.height_range[0]}&max_length=${this.height_range[1]}&min_width=${this.width_range[0]}&max_width=${this.width_range[1]}&min_height=${this.long_range[0]}&max_height=${this.long_range[1]}`)
            .then( response=> {
                this.product = response.data.products.data;
                this.total_page = response.data.products;
                this.meta = response.data
            })
            .catch(e=> {
                this.errors.push(e);
            })
        },
        clickCallback(pageNum) {
            this.selectedPage = pageNum;
        },
        updateSlider(e) {
            let clickedElement = e.target,
                min = clickedElement.min,
                max = clickedElement.max,
                val = clickedElement.value;
            this.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
        }
    },
    computed: {
		filteredPeople: function() {
			var vm = this;
			var category = vm.countries_check;
			
			if(category === this.item.id) {
				return vm.filter;
			} else {
				return vm.item.filter(function(item) {
					return item.category === category;
				});
			}
		}
	},
    watch: {
        $route() {
            this.getProduct();
            this.getTranslate()
            this.links[2].title = this.category_title;
        },
        sort_by() {
            this.getProduct()
        },
        // status_code() {
        //     this.getProduct()
        // }
    },
    
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/catalog.scss';
</style>